import Autosuggest from "models/autosuggest"
import { Action, Types } from "../actions/autosuggest"

export type AutosuggestState = {
  data: Autosuggest | undefined
  errors: {}
  loading: boolean
}

const initialState: AutosuggestState = {
  data: undefined,
  errors: {},
  loading: false
}

export default (state = initialState, action: Action): AutosuggestState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors
      }

    default:
      return state
  }
}
