import { Action, Types } from "../actions/drawer"

export type DrawerState = {
  open: boolean
}

const initialState: DrawerState = {
  open: false
}

export default (state = initialState, action: Action): DrawerState => {
  switch (action.type) {
    case Types.TOGGLE:
      return {
        ...state,
        open: !state.open
      }

    default:
      return state
  }
}
