import { sendInvitation } from "api/invitation"
import { call, put, takeLatest } from "redux-saga/effects"
import {
  InvitationActionTypes,
  sendInvitationFailure,
  SendInvitationRequestAction,
  sendInvitationSuccess,
  show as showNotification
} from "store/actions"

export function* sendInvitationRequestSaga(action: SendInvitationRequestAction) {
  try {
    yield call(sendInvitation, action.payload)

    yield put(sendInvitationSuccess())
    yield put(showNotification({ id: "invitationSuccess", message: "invitation.request.success", variant: "success" }))
  } catch (error) {
    if (error instanceof Error) {
      yield put(sendInvitationFailure(error.message))
      yield put(showNotification({ id: "invitationFailed", message: "invitation.request.failed", variant: "error" }))
    }
  }
}

export default function* invitationSaga() {
  yield takeLatest(InvitationActionTypes.SEND_INVITATION_REQUEST, sendInvitationRequestSaga)
}
