import autosuggestReducer from "./autosuggest"
import consultantReducer from "./consultants"
import cvReducer from "./cv"
import drawerReducer from "./drawer"
import educationReducer from "./education"
import experienceReducer from "./experience"
import invitationReducer from "./invitation"
import notificationsReducer from "./notifications"
import onboardingReducer from "./onboarding"
import permissionsReducer from "./permissions"
import referenceReducer from "./references"
import skillReducer from "./skill"
import tabsReducer from "./tabs"

export default {
  autosuggest: autosuggestReducer,
  consultant: consultantReducer,
  cv: cvReducer,
  drawer: drawerReducer,
  education: educationReducer,
  experience: experienceReducer,
  reference: referenceReducer,
  notification: notificationsReducer,
  onboarding: onboardingReducer,
  permissions: permissionsReducer,
  skill: skillReducer,
  tabs: tabsReducer,
  invitation: invitationReducer
}
