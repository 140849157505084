import * as api from "api/cv"
import { Reference } from "models/cv"
import { call, put, select, takeLatest } from "redux-saga/effects"
import * as cvActions from "../actions/cv"
import * as actions from "../actions/reference"
import * as CVSelectors from "../selectors/cv"

export function* postReferenceflow(
  action: actions.PostReferenceAction
): IterableIterator<any> {
  try {
    const data = action.payload.data
    const id = yield select(CVSelectors.getCvId)
    if (!id) throw new Error()
    yield call(api.postReference, data, id)
    yield put(cvActions.getCVById(id))
  } catch (e) {
    yield put(actions.postReferenceFailAction(e))
  }
}

export function* deleteReferenceByIdFlow(
  action: actions.DeleteReferenceByIdAction
): IterableIterator<any> {
  try {
    const { referenceId, id } = action.payload
    const response: any = yield call(api.deleteReferenceById, id, referenceId)
    yield put(actions.DeleteReferenceByIdSuccessAction(response))
    yield put(cvActions.getCVById(id!))
  } catch (e) {
    yield put(actions.DeleteReferenceByIdFailAction(e))
  }
}
export function* patchReferenceFlow(
  action: actions.PatchReferenceAction
): IterableIterator<any> {
  try {
    const id = yield select(CVSelectors.getCvId)
    const { referenceId } = action.payload
    const response = yield call(
      api.patchReference,
      action.payload.data,
      id,
      referenceId
    )
    const data: Reference = response && response.data[1][0]
    yield put(cvActions.getCVById(id!))
    yield put(actions.patchReferenceSuccess(data))
  } catch (e) {
    yield put(actions.patchReferenceFail(e))
  }
}
export default function* ReferenceSaga(): IterableIterator<any> {
  yield takeLatest(actions.Types.POST, postReferenceflow)
  yield takeLatest(actions.Types.DELETE, deleteReferenceByIdFlow)
  yield takeLatest(actions.Types.PATCH, patchReferenceFlow)
}
