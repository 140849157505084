import { Auth0Client } from "@auth0/auth0-spa-js"
import axios, { AxiosInstance, AxiosStatic } from "axios"
import { API_URLS, AUTH0_CONFIG, USER_URL } from "./config"
import { createApiInstance } from "./instance"
import { requestInterceptor, responseErrorInterceptor, responseInterceptor } from "./interceptors"

export function createProtectedInstance(axiosInstance: AxiosStatic, url: string): AxiosInstance {
  const instance = createApiInstance(axiosInstance, url)
  instance.interceptors.request.use(requestInterceptor)
  instance.interceptors.response.use(responseInterceptor, responseErrorInterceptor)
  return instance
}

function createAuth0Instance(): Auth0Client {
  return new Auth0Client(AUTH0_CONFIG)
}

export const auth0Client = createAuth0Instance()
export const cvApi = createProtectedInstance(axios, API_URLS.cv)
export const userApi = createProtectedInstance(axios, USER_URL)
