import styled from "@emotion/styled"
import { empleoTheme } from "config/theme"

export const StyledHeader = styled.header`
  h1 {
    padding-left: 1.5rem;
    display: flex;
    align-items: center;
  }
  background: ${empleoTheme.colors.white};
  height: 7rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${empleoTheme.colors.border};
`
export const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  .MuiSvgIcon-root {
    font-size: inherit;
    width: 3rem;
    height: 3rem;
    margin-bottom: -0.4em;
  }
  span {
    font-size: 2.5rem;
  }
`
