import { Action, Types } from "../actions/reference"

export type ReferencesState = {
  errors: object
  loading: boolean
}

const initialState: ReferencesState = {
  errors: {},
  loading: false
}

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case Types.POST:
      return {
        ...state,
        loading: true
      }
    case Types.POST_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case Types.POST_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }
    case Types.DELETE:
      return {
        ...state,
        loading: true
      }

    case Types.DELETE_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.DELETE_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }
    case Types.PATCH:
      return {
        ...state
      }

    case Types.PATCH_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.PATCH_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }
    default:
      return state
  }
}
