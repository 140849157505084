import Cv from "models/cv"
import { CVActions, CVActionTypes } from "store/actions"

export type CvState = {
  cv: Cv | undefined
  errors: object
  loading: boolean
}

const initialState: CvState = {
  cv: undefined,
  errors: {},
  loading: false
}

export default (state = initialState, action: CVActions): CvState => {
  switch (action.type) {
    case CVActionTypes.CLEAR:
      return {
        ...state,
        cv: undefined
      }

    case CVActionTypes.DELETE:
      return {
        ...state
      }

    case CVActionTypes.DELETE_SUCCESS:
      return {
        ...state
      }

    case CVActionTypes.DELETE_FAIL:
      return {
        ...state,
        errors: action.payload.errors
      }

    case CVActionTypes.POST:
      return {
        ...state
      }

    case CVActionTypes.POST_SUCCESS:
      return {
        ...state
      }

    case CVActionTypes.POST_FAIL:
      return {
        ...state,
        errors: action.payload.errors
      }

    case CVActionTypes.GET_BY_ID:
      return {
        ...state
      }

    case CVActionTypes.GET_BY_ID_SUCCESS:
      return {
        ...state,
        cv: action.payload.data
      }

    case CVActionTypes.GET_BY_ID_FAIL:
      return {
        ...state,
        errors: action.payload.errors
      }

    case CVActionTypes.PATCH:
      return {
        ...state
      }

    case CVActionTypes.PATCH_SUCCESS:
      return {
        ...state
      }

    case CVActionTypes.PATCH_FAIL:
      return {
        ...state,
        errors: action.payload.errors
      }

    default:
      return state
  }
}
