import Notification from "models/notification"
import { Action, Types } from "../actions/notifications"

export type NotificationState = {
  notifications: Notification[]
}

const initialState: NotificationState = {
  notifications: []
}

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case Types.SHOW:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            id: action.payload.id,
            message: action.payload.message,
            values: action.payload.values,
            variant: action.payload.variant
          }
        ]
      }

    case Types.HIDE:
      return {
        ...state,
        notifications: state.notifications.filter(
          n => n.id !== action.payload.id
        )
      }

    default:
      return state
  }
}
