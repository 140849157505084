export enum Types {
  GET = "PERMISSIONS_GET",
  GET_SUCCESS = "PERMISSIONS_GET_SUCCESS",
  GET_FAIL = "PERMISSIONS_GET_FAIL"
}

export type Action =
  | GetPermissionsAction
  | GetPermissionsSuccessAction
  | GetPermissionsFailAction

export type GetPermissionsAction = {
  type: Types.GET
}

export type GetPermissionsSuccessAction = {
  payload: { data: string[] }
  type: Types.GET_SUCCESS
}

export type GetPermissionsFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}
export function getPermissions(): GetPermissionsAction {
  return {
    type: Types.GET
  }
}

export function getPermissionsSuccess(
  data: string[]
): GetPermissionsSuccessAction {
  return {
    payload: { data },
    type: Types.GET_SUCCESS
  }
}

export function getPermissionsFail(errors: object): GetPermissionsFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}
