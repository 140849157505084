import INotification from "models/notification"

export enum Types {
  SHOW = "NOTIFICATIONS_SHOW",
  HIDE = "NOTIFICATIONS_HIDE"
}
export type HideAction = {
  type: Types.HIDE
  payload: { id: string }
}

export type ShowAction = {
  type: Types.SHOW
  payload: INotification
}

export type Action = HideAction | ShowAction

export function show({ id, message, values, variant }: INotification): ShowAction {
  return {
    payload: { id, message, values, variant },
    type: Types.SHOW
  }
}

export function hide(id: string): HideAction {
  return {
    payload: { id },
    type: Types.HIDE
  }
}
