import { Reference } from "models/cv"

export enum Types {
  POST = "REFERENCE_POST",
  POST_SUCCESS = "REFERENCE_POST_SUCCESS",
  POST_FAIL = "REFERENCE_POST_FAIL",
  DELETE = "REFERENCE_DELETE",
  DELETE_SUCCESS = "REFERENCE_DELETE_SUCCESS",
  DELETE_FAIL = "REFERENCE_DELETE_FAIL",
  PATCH = "REFERENCE_PATCH",
  PATCH_SUCCESS = "REFERENCE_PATCH_SUCCESS",
  PATCH_FAIL = "REFERENCE_PATCH_FAIL"
}

export type Action =
  | PostReferenceAction
  | PostReferenceSuccessAction
  | PostReferenceFailAction
  | DeleteReferenceByIdAction
  | DeleteReferenceByIdSuccessAction
  | DeleteReferenceByIdFailAction
  | PatchReferenceAction
  | PatchReferenceSuccessAction
  | PatchReferenceFailAction

export type PostReferenceAction = {
  payload: { data: Reference }
  type: Types.POST
}

export type PostReferenceSuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostReferenceFailAction = {
  payload: { errors: object }
  type: Types.POST_FAIL
}

export type DeleteReferenceByIdAction = {
  payload: { id: string, referenceId: string }
  type: Types.DELETE
}

export type DeleteReferenceByIdSuccessAction = {
  type: Types.DELETE_SUCCESS,
  payload: { data: Reference | undefined }
}

export type DeleteReferenceByIdFailAction = {
  payload: { errors: object }
  type: Types.DELETE_FAIL
}

export type PatchReferenceAction = {
  type: Types.PATCH
  payload: { data: Reference, referenceId: string }
}

export type PatchReferenceSuccessAction = {
  type: Types.PATCH_SUCCESS
  payload: { data: Reference | undefined }
}

export type PatchReferenceFailAction = {
  type: Types.PATCH_FAIL
  payload: { errors: object }
}

export function postReferenceAction(data: Reference): PostReferenceAction {
  return {
    payload: { data },
    type: Types.POST
  }
}

export function postReferenceSuccessAction(): PostReferenceSuccessAction {
  return {
    type: Types.POST_SUCCESS
  }
}

export function postReferenceFailAction(errors: object): PostReferenceFailAction {
  return {
    payload: { errors },
    type: Types.POST_FAIL
  }
}

export function DeleteReferenceByIdAction(id: string, referenceId: string): DeleteReferenceByIdAction {
  return {
    payload: { id, referenceId },
    type: Types.DELETE
  }
}

export function DeleteReferenceByIdSuccessAction(data: Reference): DeleteReferenceByIdSuccessAction {
  return {
    payload: { data },
    type: Types.DELETE_SUCCESS
  }
}

export function DeleteReferenceByIdFailAction(errors: object): DeleteReferenceByIdFailAction {
  return {
    payload: { errors },
    type: Types.DELETE_FAIL
  }
}

export function patchReference(data: Reference, referenceId: string): PatchReferenceAction {
  return {
    payload: { data, referenceId },
    type: Types.PATCH
  }
}
export function patchReferenceSuccess(data: Reference): PatchReferenceSuccessAction {
  return {
    payload: { data },
    type: Types.PATCH_SUCCESS
  }
}
export function patchReferenceFail(errors: object): PatchReferenceFailAction {
  return {
    payload: { errors },
    type: Types.PATCH_FAIL
  }
}
