import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import { getToken } from "./oauth"

export async function requestInterceptor(config: AxiosRequestConfig) {
  const accessToken: string = await getToken()
  config.headers.Authorization = `Bearer ${accessToken}`
  return config
}

export function responseInterceptor(response: AxiosResponse) {
  return response
}

export function responseErrorInterceptor(error: AxiosError) {
  if (error && error.response && error.response.status === 401) {
    // @TODO: add error handling
  }

  return Promise.reject(error)
}
