import Consultant from "models/consultants"
import { UserSearch } from "models/user"

export enum Types {
  GET = "CONSULTANTS_GET",
  GET_SUCCESS = "CONSULTANTS_GET_SUCCESS",
  GET_FAIL = "CONSULTANTS_GET_FAIL",
  SET_CHECKBOXES = "CHECKBOXES_SET",
  RESET_SELECTION = "RESET_SELECTION"
}

export type Action =
  | GetConsultantAction
  | GetConsultantSuccessAction
  | GetConsultantFailAction
  | SelectCheckboxAction
  | ResetSelectionAction

export type GetConsultantAction = {
  payload: { params: string }
  type: Types.GET
}

export type GetConsultantSuccessAction = {
  payload: {
    data: Consultant[] | undefined
    currentPage: number
    amountOfPages: number
    skill: string[]
    user: UserSearch[]
  }
  type: Types.GET_SUCCESS
}

export type GetConsultantFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export type SelectCheckboxAction = {
  payload: { id: string }
  type: Types.SET_CHECKBOXES
}

export type ResetSelectionAction = {
  type: Types.RESET_SELECTION
}

export function getConsultant(params: string): GetConsultantAction {
  return {
    payload: { params },
    type: Types.GET
  }
}

export function getConsultantSuccess(
  data: Consultant[] | undefined,
  currentPage: number,
  amountOfPages: number,
  skill: string[],
  user: UserSearch[]
): GetConsultantSuccessAction {
  return {
    payload: {
      amountOfPages,
      currentPage,
      data,
      skill,
      user
    },
    type: Types.GET_SUCCESS
  }
}

export function getConsultantFail(errors: object): GetConsultantFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function handleSelect(id: string): SelectCheckboxAction {
  return {
    payload: { id },
    type: Types.SET_CHECKBOXES
  }
}

export function resetSelection(): ResetSelectionAction {
  return {
    type: Types.RESET_SELECTION
  }
}
