import * as api from "api/cv"
import { Education } from "models/cv"
import { call, put, select, takeLatest } from "redux-saga/effects"
import * as cvActions from "../actions/cv"
import * as actions from "../actions/education"
import * as CVSelectors from "../selectors/cv"

export function* postEducationflow(
  action: actions.PostEducationAction
): IterableIterator<any> {
  try {
    const data = action.payload.data
    const id = yield select(CVSelectors.getCvId)
    if (!id) throw new Error()
    yield call(api.postEducation, data, id)
    yield put(cvActions.getCVById(id))
  } catch (e) {
    yield put(actions.postEducationFailAction(e))
  }
}

export function* deleteEducationByIdFlow(
  action: actions.DeleteEducationByIdAction
): IterableIterator<any> {
  try {
    const { educationId, id } = action.payload
    const response: any = yield call(api.deleteEducationById, id, educationId)
    yield put(actions.DeleteEducationByIdSuccessAction(response))
    yield put(cvActions.getCVById(id!))
  } catch (e) {
    yield put(actions.DeleteEducationByIdFailAction(e))
  }
}
export function* patchEducationFlow(
  action: actions.PatchEducationAction
): IterableIterator<any> {
  try {
    const id = yield select(CVSelectors.getCvId)
    const { educationId } = action.payload
    const response = yield call(
      api.patchEducation,
      action.payload.data,
      id,
      educationId
    )
    const data: Education = response && response.data[1][0]
    yield put(cvActions.getCVById(id!))
    yield put(actions.patchEducationSuccess(data))
  } catch (e) {
    yield put(actions.patchEducationFail(e))
  }
}
export default function* educationSaga(): IterableIterator<any> {
  yield takeLatest(actions.Types.POST, postEducationflow)
  yield takeLatest(actions.Types.DELETE, deleteEducationByIdFlow)
  yield takeLatest(actions.Types.PATCH, patchEducationFlow)
}
