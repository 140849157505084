import { CheckboxProps } from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Typography from "@material-ui/core/Typography"
import CheckIcon from "@material-ui/icons/CheckCircleOutlined"
import React, { memo } from "react"
import { useIntl } from "react-intl"
import Translation from "../../Translation/Translation"
import { Flex, StyledInput, StyledValue } from "./style"

type Props = {
  checked: boolean
  checkIcon?: boolean
  edited?: boolean
  placeholderText: string
  onValueChange: (value: boolean, name: string) => void
} & CheckboxProps

function Checkbox({
  checked,
  checkIcon,
  edited,
  placeholderText,
  onValueChange,
  ...rest
}: Props): JSX.Element {
  const { messages } = useIntl()
  const placeholder = messages[placeholderText] || placeholderText

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onValueChange(event.currentTarget.checked, event.currentTarget.name)
  }

  if (edited) {
    return (
      <FormControlLabel
        control={
          <StyledInput
            color="primary"
            checked={checked}
            onChange={handleChange}
            placeholder={placeholder as string}
            {...rest}
          />
        }
        label={placeholder}
      />
    )
  }

  return (
    <StyledValue isNotEmpty={checked}>
      <Flex>
        {checked && checkIcon && <CheckIcon />}
        <Typography>
          <Translation label={placeholderText} />
        </Typography>
      </Flex>
    </StyledValue>
  )
}

export default memo(Checkbox)
