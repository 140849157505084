import * as api from "api/consultants"
import { call, put, takeLatest } from "redux-saga/effects"
import { TConsultantsResponse } from "../../api/consultants"
import * as actions from "../actions/consultants"
import * as notificationActions from "../actions/notifications"
export function* getConsultantsFlow(
  action: actions.GetConsultantAction
): IterableIterator<any> {
  try {
    const response = yield call(api.getConsultants, action.payload.params)
    if (response) {
      const {
        data,
        amountOfPages,
        currentPage,
        skill,
        user
      }: TConsultantsResponse["data"] = (response as TConsultantsResponse).data
      yield put(
        actions.getConsultantSuccess(
          data,
          currentPage,
          amountOfPages,
          skill,
          user
        )
      )
    }
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "getConsultantsFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.getConsultantFail(e))
  }
}

export default function* cvSaga(): IterableIterator<any> {
  yield takeLatest(actions.Types.GET, getConsultantsFlow)
}
