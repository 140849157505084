import { AxiosPromise } from "axios"
import { ProjectInVersion, SkillInVersion, Version } from "models/cv"
import { cvApi } from "./api"

export const postVersion = (data: Version, userId: string): AxiosPromise =>
  cvApi(`/cv/${userId}/version`, { method: "POST", data })

export const deleteVersion = (
  userId: string,
  versionId: string | number
): AxiosPromise =>
  cvApi(`/cv/${userId}/version/${versionId}`, {
    method: "DELETE"
  })

export const patchVersion = (
  data: Version,
  versionId: string | number,
  userId: string
): AxiosPromise =>
  cvApi(`/cv/${userId}/version/${versionId}`, {
    data,
    method: "PATCH"
  })

export const patchVersionSkill = (
  data: SkillInVersion,
  versionId: string | number,
  userId: string,
  skillId: string | number
): AxiosPromise =>
  cvApi(`/cv/${userId}/version/${versionId}/skill/${skillId}`, {
    data,
    method: "PATCH"
  })

export const patchVersionProject = (
  data: ProjectInVersion,
  versionId: string | number,
  userId: string,
  projectId: string | number
): AxiosPromise =>
  cvApi(`/cv/${userId}/version/${versionId}/project/${projectId}`, {
    data,
    method: "PATCH"
  })
