import { ComponentType, lazy } from "react"

const RootPage = lazy(() => import("pages/Root"))
const LogoutPage = lazy(() => import("pages/Logout"))
const ConsultantsPage = lazy(() => import("pages/Consultants"))
const CVDetailPage = lazy(() => import("pages/CVDetail"))
const InvitationPage = lazy(() => import("pages/Invitation"))

enum RoutesEnum {
  Home = "",
  YourCV = "your-cv",
  Consultants = "consultants",
  Invitation = "invitation",
  Logout = "logout"
}

interface Route {
  path: string
  exact: boolean
  authorized: boolean
  component: ComponentType<any>
}

const routes: Route[] = [
  {
    path: RoutesEnum.Home,
    exact: true,
    authorized: true,
    component: RootPage
  },
  {
    path: RoutesEnum.YourCV,
    exact: true,
    authorized: true,
    component: CVDetailPage
  },
  {
    path: RoutesEnum.Consultants,
    exact: true,
    authorized: true,
    component: ConsultantsPage
  },
  {
    path: `${RoutesEnum.Consultants}/:id`,
    exact: true,
    authorized: true,
    component: CVDetailPage
  },
  {
    path: RoutesEnum.Invitation,
    exact: true,
    authorized: true,
    component: InvitationPage
  },
  {
    path: RoutesEnum.Logout,
    exact: true,
    authorized: false,
    component: LogoutPage
  }
]

export { RoutesEnum }
export default routes
