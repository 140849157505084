import { Action, Types } from "../actions/permissions"

export type PermissionsState = {
  data: string[]
  loading: boolean
  errors: object
}

const initialState: PermissionsState = {
  data: [],
  errors: {},
  loading: false
}

export default (state = initialState, action: Action): PermissionsState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    default:
      return state
  }
}
