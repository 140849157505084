import { Experience } from "models/cv"

export enum Types {
  PATCH = "EXPERIENCE_PATCH",
  PATCH_SUCCESS = "EXPERIENCE_PATCH_SUCCESS",
  PATCH_FAIL = "EXPERIENCE_PATCH_FAIL",
  POST = "EXPERIENCE_POST",
  POST_SUCCESS = "EXPERIENCE_POST_SUCCESS",
  POST_FAIL = "EXPERIENCE_POST_FAIL",
  DELETE = "EXPERIENCE_DELETE",
  DELETE_SUCCESS = "EXPERIENCE_DELETE_SUCCESS",
  DELETE_FAIL = "EXPERIENCE_DELETE_FAIL"
}

export type Action =
  | PostExperienceAction
  | PostExperienceSuccessAction
  | PostExperienceFailAction
  | PatchExperienceAction
  | PatchExperienceSuccessAction
  | PatchExperienceFailAction
  | DeleteExperienceAction
  | DeleteExperienceSuccessAction
  | DeleteExperienceFailAction

export type PostExperienceAction = {
  payload: { data: Experience }
  type: Types.POST
}

export type PostExperienceSuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostExperienceFailAction = {
  payload: { errors: object }
  type: Types.POST_FAIL
}

export type PatchExperienceAction = {
  payload: { data: Experience }
  type: Types.PATCH
}

export type PatchExperienceSuccessAction = {
  type: Types.PATCH_SUCCESS
}

export type PatchExperienceFailAction = {
  payload: { errors: object }
  type: Types.PATCH_FAIL
}

export type DeleteExperienceAction = {
  payload: { experienceId: string }
  type: Types.DELETE
}

export type DeleteExperienceSuccessAction = {
  type: Types.DELETE_SUCCESS
}

export type DeleteExperienceFailAction = {
  payload: { errors: object }
  type: Types.DELETE_FAIL
}

export function postExperience(data: Experience): PostExperienceAction {
  return {
    payload: { data },
    type: Types.POST
  }
}

export function postExperienceSuccess(): PostExperienceSuccessAction {
  return {
    type: Types.POST_SUCCESS
  }
}

export function postExperienceFail(errors: object): PostExperienceFailAction {
  return {
    payload: { errors },
    type: Types.POST_FAIL
  }
}

export function patchExperience(data: Experience): PatchExperienceAction {
  return {
    payload: { data },
    type: Types.PATCH
  }
}

export function patchExperienceSuccess(): PatchExperienceSuccessAction {
  return {
    type: Types.PATCH_SUCCESS
  }
}

export function patchExperienceFail(errors: object): PatchExperienceFailAction {
  return {
    payload: { errors },
    type: Types.PATCH_FAIL
  }
}

export function deleteExperience(experienceId: string): DeleteExperienceAction {
  return {
    payload: { experienceId },
    type: Types.DELETE
  }
}

export function deleteExperienceSuccess(): DeleteExperienceSuccessAction {
  return {
    type: Types.DELETE_SUCCESS
  }
}

export function deleteExperienceFail(
  errors: object
): DeleteExperienceFailAction {
  return {
    payload: { errors },
    type: Types.DELETE_FAIL
  }
}
