import { ButtonBase, DialogActions, Typography } from "@material-ui/core"
import MUIDialog from "@material-ui/core/Dialog"
import Close from "@material-ui/icons/Close"
import React, { memo } from "react"
import { Content, Title } from "./style"

type Props = {
  children: JSX.Element
  open: boolean
  title: string | JSX.Element
  onClose: () => void
}

function Dialog({ children, open, onClose, title }: Props): JSX.Element {
  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Content>
        <Title id="alert-dialog-title">
          <strong>
            <Typography variant="h3">{title}</Typography>
          </strong>
          <ButtonBase onClick={onClose}>
            <Close fontSize="large" />
          </ButtonBase>
        </Title>
        <div>{children}</div>
        <DialogActions />
      </Content>
    </MUIDialog>
  )
}

export default memo(Dialog)
