import { AxiosPromise } from "axios"
import Consultant from "models/consultants"
import { UserSearch } from "models/user"
import { cvApi } from "./api"

type TConsultantsResponseData = {
  data: Consultant[]
  amountOfPages: number
  currentPage: number
  user: UserSearch[]
  skill: string[]
  activeCheckboxes: string[]
}

export type TConsultantsResponse = {
  data: TConsultantsResponseData
}

export const getConsultants = (
  params?: string
): AxiosPromise<TConsultantsResponse> =>
  cvApi(`/cv/${params}`, {
    method: "GET"
  })
