import { CssBaseline } from "@material-ui/core"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { createGenerateClassName, StylesProvider } from "@material-ui/styles"
import LoadingScreen from "components/LoadingScreen"
import theme from "config/theme"
import { ConnectedRouter } from "connected-react-router"
import moment from "moment"
import "moment/locale/en-gb"
import "moment/locale/nl-be"
import React, { Suspense } from "react"
import { IntlProvider } from "react-intl"
import { Provider } from "react-redux"
import "./app.css"
import { translations } from "./i18n/config"
import Auth0ProviderWithHistory from "./providers/auth0ProviderWithHistory"
import Router from "./routes/router"
import store, { history } from "./store/store"

const generateClassName = createGenerateClassName()

const locale = "en"
moment.locale(locale)

function App() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Provider store={store}>
        <StylesProvider generateClassName={generateClassName}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <IntlProvider messages={translations[locale]} locale={locale}>
              <ConnectedRouter history={history}>
                <Auth0ProviderWithHistory>
                  <Router />
                </Auth0ProviderWithHistory>
              </ConnectedRouter>
            </IntlProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </Provider>
    </Suspense>
  )
}

export default App
