import enTranslations from "./en.translations.json"
import nlTranslations from "./nl.translations.json"

export enum Locales {
  EN = "en",
  NL = "nl"
}

const locales = [Locales.EN, Locales.NL]

const translations = {
  [Locales.EN]: {
    ...enTranslations
  },
  [Locales.NL]: {
    ...nlTranslations
  }
}

const formats = {}

export { locales, translations, formats }
