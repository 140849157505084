import styled from "@emotion/styled"
import { Button } from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import { empleoTheme } from "config/theme"

export const StyledAppBar = styled(AppBar)`
  background-color: ${empleoTheme.colors.white};
  position: sticky;
  box-shadow: none;
  border-bottom: 0.1rem solid ${empleoTheme.colors.border};
`

export const ToolbarWrapper = styled(Toolbar)`
  background-color: ${empleoTheme.colors.white};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
`
export const SearchWrapper = styled.div`
  position: relative;
  .MuiInputBase-input {
    padding: 1rem 3.5rem 1.1rem;
    width: 50rem;
    margin: 0.5rem;
    border-radius: 1rem;
    border: 0.1rem solid ${empleoTheme.colors.border};
    margin-left: 0;
  }
`
export const SearchIconWrapper = styled.div`
  margin-left: 7px;
  width: 100%;
  height: 100%;
`

export const ChipWrapper = styled.div`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  max-width: 90%;
  overflow-y: scroll;
  div {
    margin-right: 1rem;
  }
`

export const Reset = styled(Button)`
  font-size: 1.3rem;
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
`
