import { deleteLanguage, patchLanguage, postLanguage } from "api/cv"
import { call, put, select, takeLatest } from "redux-saga/effects"
import * as cvActions from "../actions/cv"
import * as actions from "../actions/language"
import * as notificationActions from "../actions/notifications"
import * as CVSelectors from "../selectors/cv"

export function* postLanguageFlow(
  action: actions.PostLanguageAction
): IterableIterator<any> {
  try {
    const { data } = action.payload
    const id = yield select(CVSelectors.getCvId)
    yield call(postLanguage, data, id)
    yield put(cvActions.getCVById(id!))
    yield put(actions.postLanguageSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "postLanguageFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.postLanguageFail(e))
  }
}

export function* deleteLanguageFlow(
  action: actions.DeleteLanguageAction
): IterableIterator<any> {
  try {
    const { languageId } = action.payload
    const id = yield select(CVSelectors.getCvId)
    yield call(deleteLanguage, id, languageId)
    yield put(cvActions.getCVById(id!))
    yield put(actions.deleteLanguageSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "deleteLanguageFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.deleteLanguageFail(e))
  }
}

export function* patchLanguageFlow(
  action: actions.PatchLanguageAction
): IterableIterator<any> {
  try {
    const { data } = action.payload
    const languageId = data.languageId
    delete data.languageId
    const id = yield select(CVSelectors.getCvId)
    if (id && languageId) {
      yield call(patchLanguage, data, id, languageId)
      yield put(cvActions.getCVById(id))
    }
    yield put(actions.patchLanguageSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "patchLanguageFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.patchLanguageFail(e))
  }
}

export default function* languagesSaga(): IterableIterator<any> {
  yield takeLatest(actions.Types.PATCH, patchLanguageFlow)
  yield takeLatest(actions.Types.POST, postLanguageFlow)
  yield takeLatest(actions.Types.DELETE, deleteLanguageFlow)
}
