import { Button, Chip, Grid } from "@material-ui/core"
import Select from "components/Form/AdvancedSearch"
import Checkbox from "components/Form/Checkbox"
import Consultant from "models/consultants"
import { Skill } from "models/cv"
import React, { memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router"
import * as actions from "store/actions/autosuggest"
import * as selectors from "store/selectors/autosuggest"
import {
  getActiveCheckboxes,
  getSkills,
  getUsers
} from "store/selectors/consultants"
import DownloadCv from "../DownloadBulkCv"
import Translation from "../Translation/Translation"
import Sorting from "./Sorting"
import {
  ChipWrapper,
  Reset,
  SearchIconWrapper,
  SearchWrapper,
  StyledAppBar,
  ToolbarWrapper,
  Wrapper
} from "./style"

function StickySearchbar({
  history,
  location,
  location: { search }
}: RouteComponentProps) {
  const [searchValue, setSearchValue] = useState<string>("")
  const [modalIsVisible, setModalVisibility] = useState<boolean>(false)
  const dispatch = useDispatch()
  const p = new URLSearchParams(search)
  const queries = p.getAll("search")
  const usersParam = p.getAll("user")
  const skillsParam = p.getAll("skill")
  const getParamSkills = useSelector(getSkills)
  const getParamUsers = useSelector(getUsers)
  const checkboxes = useSelector(getActiveCheckboxes)
  const names = useSelector(selectors.getAutosuggestNames)
  const skills = useSelector(selectors.getAutosuggestSkills)

  useEffect(() => {
    if (searchValue.length > 0) {
      dispatch(actions.getAutosuggest(searchValue))
    }
  }, [dispatch, searchValue])

  useEffect(() => {
    if (!queries.length && p.get("sort") === "relevance") {
      p.set("sort", "firstName")
      history.push(`${location.pathname}?${p.toString()}`)
    }
  }, [history, location.pathname, p, queries])

  function handleDownload() {
    setModalVisibility(!modalIsVisible)
  }

  function handleReset() {
    p.set("sort", "firstName")
    p.set("page", "1")
    p.delete("search")
    p.delete("skill")
    p.delete("user")
    history.push(`${location.pathname}?${p.toString()}`)
  }

  const onEnter = (value: string) => {
    if (!queries.includes(value) && value.length > 0) {
      p.set("sort", "relevance")
      p.set("page", "1")
      p.append("search", value)
      history.push(`${location.pathname}?${p.toString()}`)
    }
    setSearchValue("")
  }

  function handleChange(value: string) {
    setSearchValue(value)
  }

  function handleSelect(name: string) {
    const category = name && name.substr(0, name.indexOf(":"))
    const query = name && name.substr(name.indexOf(":") + 1)

    if (
      name &&
      !queries.includes(query) &&
      !skillsParam.includes(query) &&
      !usersParam.includes(query)
    ) {
      if (!p.toString().includes(`${category}=${query}`)) {
        p.append(`${category}`, query)
      }
      history.push(`${location.pathname}?${p.toString()}`)
    }
  }

  const showSuspended = p.get("showSuspended") !== null

  function handleSetShowSuspended(value: boolean)  {
    if (!showSuspended) {
      p.append("showSuspended", value.toString())
    } else p.delete("showSuspended")
    history.push(`${location.pathname}?${p.toString()}`)
  }

  async function handleDelete(val: string) {
    const before = val && val.substr(0, val.indexOf(":"))
    const after = val && val.substr(val.indexOf(":") + 1)

    switch (before) {
      case "skill":
        const filteredSkills = skillsParam.filter(skill => skill !== after)
        p.delete("skill")
        if (filteredSkills.length > 0) {
          filteredSkills.forEach(q => p.append("skill", q))
        }
        break
      case "user":
        const getUserId =
          getParamUsers &&
          (await getParamUsers.filter(user => user.userName === after))

        const filteredNames =
          getUserId &&
          (await usersParam.filter(user => user !== getUserId[0].userId))
        p.delete("user")
        if (filteredNames && filteredNames.length > 0) {
          filteredNames.forEach(q => p.append("user", q))
        }
        break
      default:
        const filteredQueries = queries.filter(query => query !== val)
        p.delete("search")
        if (filteredQueries.length > 0) {
          filteredQueries.forEach(q => p.append("search", q))
        }
    }

    p.set("page", "1")
    history.push(`${location.pathname}?${p.toString()}`)
  }
  function generateOptions() {
    let freesearch: any = []

    if (searchValue.length > 0) {
      freesearch = [
        {
          label: `'${searchValue}' - seach entire CV `,
          value: `search:${searchValue}`
        }
      ]
    }

    if (names || skills) {
      const dataNames =
        names &&
        Object.values(names).map((user: Consultant) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: `user:${user.userId}`
        }))
      const dataSkills =
        skills &&
        skills.map((skill: Skill) => ({
          label: skill.name,
          value: `skill:${skill.name}`
        }))

      return [
        {
          label: "",
          options: freesearch,
          value: searchValue
        },
        {
          label: "Skills",
          options: dataSkills,
          value: skills ? skills.length : 0
        },
        {
          label: "Name",
          options: dataNames,
          value: dataNames ? dataNames.length : 0
        }
      ]
    }
    return []
  }

  function setQueryChip() {
    const queryChipArray: string[] = []
    if (queries && queries.length > 0) {
      queries.map(query => queryChipArray.push(`${query}`))
    }
    if (getParamSkills && getParamSkills.length > 0) {
      getParamSkills.map(skill => queryChipArray.push(`skill:${skill}`))
    }
    if (getParamUsers && getParamUsers.length > 0) {
      getParamUsers.map(user => queryChipArray.push(`user:${user.userName}`))
    }
    return queryChipArray
  }

  return (
    <StyledAppBar>
      <ToolbarWrapper>
        <Grid container justifyContent="space-between">
          <SearchWrapper>
            <SearchIconWrapper>
              <Select
                edited
                value={searchValue}
                onInputChange={handleChange}
                onEnter={onEnter}
                onValueChange={(name: string) => handleSelect(name)}
                selectText="actions.search"
                options={generateOptions()}
              />
            </SearchIconWrapper>
          </SearchWrapper>
          <Checkbox
            checkIcon
            edited
            name="showSuspended"
            onValueChange={handleSetShowSuspended}
            placeholderText="consultants.showSuspended"
            checked={showSuspended}
          />
          {checkboxes.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownload}
            >
              <Translation label="yourcv.download.button" />
            </Button>
          )}

          {modalIsVisible && (
            <DownloadCv handleVisibility={setModalVisibility} />
          )}
          <Sorting />
        </Grid>
        {Object.values(setQueryChip()) && setQueryChip().length > 0 && (
          <Wrapper>
            <ChipWrapper>
              {setQueryChip().map((v, i) => (
                <Chip label={v} key={i} onDelete={() => handleDelete(v)} />
              ))}
            </ChipWrapper>

            <Reset variant="text" onClick={handleReset}>
              <Translation label="actions.reset" />
            </Reset>
          </Wrapper>
        )}
      </ToolbarWrapper>
    </StyledAppBar>
  )
}

export default withRouter(memo(StickySearchbar))
