import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as api from "../../api/permissions"
import * as actions from "../actions/permissions"

export function* getPermissionsFlow(
  action: actions.GetPermissionsAction
): SagaIterator {
  try {
    const permissions: any = yield call(api.getPermissions)
    yield put(actions.getPermissionsSuccess(permissions.data))
  } catch (e) {
    yield put(actions.getPermissionsFail(e))
  }
}

export default function* permissionsSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getPermissionsFlow)
}
