import { deleteExperience, patchExperience, postExperience } from "api/cv"
import { call, put, select, takeLatest } from "redux-saga/effects"
import * as cvActions from "../actions/cv"
import * as actions from "../actions/experience"
import * as notificationActions from "../actions/notifications"
import * as CVSelectors from "../selectors/cv"

export function* postExperienceFlow(
  action: actions.PostExperienceAction
): IterableIterator<any> {
  try {
    const { data } = action.payload
    const id = yield select(CVSelectors.getCvId)
    yield call(postExperience, data, id)
    if (id) {
      yield put(cvActions.getCVById(id))
    }
    yield put(actions.postExperienceSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "postExperienceFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.postExperienceFail(e))
  }
}

export function* deleteExperienceFlow(
  action: actions.DeleteExperienceAction
): IterableIterator<any> {
  try {
    const { experienceId } = action.payload
    const id = yield select(CVSelectors.getCvId)
    yield call(deleteExperience, id, experienceId)
    if (id) {
      yield put(cvActions.getCVById(id))
    }
    yield put(actions.deleteExperienceSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "deleteExperienceFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.deleteExperienceFail(e))
  }
}

export function* patchExperienceFlow(
  action: actions.PostExperienceAction
): IterableIterator<any> {
  try {
    const { data } = action.payload
    const experienceId = data.experienceId
    delete data.experienceId
    const id = yield select(CVSelectors.getCvId)
    if (id && experienceId) {
      yield call(patchExperience, data, id, experienceId)
      yield put(cvActions.getCVById(id))
    }
    yield put(actions.patchExperienceSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "patchExperienceFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.patchExperienceFail(e))
  }
}

export default function* experienceSaga(): IterableIterator<any> {
  yield takeLatest(actions.Types.PATCH, patchExperienceFlow)
  yield takeLatest(actions.Types.POST, postExperienceFlow)
  yield takeLatest(actions.Types.DELETE, deleteExperienceFlow)
}
