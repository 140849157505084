import Divider from "components/Divider"
import React from "react"
import { useSelector } from "react-redux"
import { hasPermissions } from "store/selectors/permissions"
import IState from "store/state"
import { IconContainer, StyledNavLink } from "./Navigation.style"

export type NavigationLinkProps = {
  icon: JSX.Element
  to: string
  label: string
  disabled?: boolean
  onClick?: (event: React.MouseEvent) => void
  permissions?: string[]
  divider?: boolean
}

export default function NavigationLink({
  onClick,
  disabled = false,
  label,
  icon,
  to,
  permissions = [],
  divider,
  ...props
}: NavigationLinkProps): JSX.Element {
  const hasPermission = useSelector((state: IState) => hasPermissions(state, permissions))
  const handleClick = (e: React.MouseEvent) => {
    if (disabled) e.preventDefault()
    if (onClick) onClick(e)
  }

  if (!hasPermission) return <></>

  return (
    <>
    <StyledNavLink to={to} onClick={handleClick} {...props}>
      <IconContainer>{icon}</IconContainer>
      {label}
      </StyledNavLink>
      {divider && <Divider />}
    </>
  )
}
