import {
  Button,
  DialogActions,
  DialogContent,
  Typography
} from "@material-ui/core"
import { cvApi } from "api/api"
import Dialog from "components/Dialog"
import Checkbox from "components/Form/Checkbox"
import { Spinner } from "components/Spinner/Spinner"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as actions from "store/actions/consultants"
import {
  getActiveCheckboxes,
  getConsultants
} from "store/selectors/consultants"
import Translation from "../Translation/Translation"
import { ActionWrapper, StyledDialogWrapper } from "./style"

type Props = {
  handleVisibility: (value: boolean) => void
}

function DownloadCv({ handleVisibility }: Props) {
  const dispatch = useDispatch()
  const checkboxes = useSelector(getActiveCheckboxes)
  const initialDownloads: any[] = []
  const [downloads, updateDownloads] = React.useState(initialDownloads)
  const [disabled, setDisabled] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false)
  const [extensions, updateExtensions] = React.useState<string[]>([])
  const consultants = useSelector(getConsultants)

  useEffect(() => {
    if (checkboxes) {
      dispatch(
        actions.getConsultant(
          `?sort=firstName${checkboxes.map(user => `&user=${user}`)}`
        )
      )
    }
  }, [checkboxes, dispatch])

  function handleSelection(value: boolean, name: string) {
    let versionData: any[] = downloads || []
    if (value) {
      versionData.push(name)
    } else {
      versionData = versionData.filter(item => item !== name)
    }
    versionData = [...new Set(versionData)]
    !!versionData.length && extensions.length ? setDisabled(false) : setDisabled(true)
    updateDownloads(versionData)
  }

  function handleCancel() {
    updateDownloads(initialDownloads)
    handleVisibility(false)
    dispatch(actions.resetSelection())
    dispatch(actions.getConsultant("?sort=firstName"))
  }

  function handleSubmit() {
    if (!!downloads.length) {
      getDownload(downloads)
    }
  }

  function getDownload(data: any) {
    const theData = { versionIds: data.map((item: any) => parseInt(item, 10)), fileTypes: extensions }
    setIsLoading(true)
    cvApi("/document", {
      data: theData,
      method: "POST",
      responseType: "blob"
    }).then(responseData => {
      const { readyState, status } = responseData.request
      const { headers } = responseData
      if (readyState === 4 && status === 200) {
        setIsLoading(false)
        download(headers.filename, responseData.data, headers["content-type"])
        handleCancel()
      }
    })
  }

  function download(filename: any, data: any, fileType: any) {
    const blob = new Blob([data], { type: fileType })
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")

    a.href = url
    a.download = filename || "download"
    a.style.display = "none"

    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url)
        a.removeEventListener("click", clickHandler)
      }, 150)
    }
    a.addEventListener("click", clickHandler, false)
    a.click()
  }

  function handleExtensions(value: boolean, name: string) {
    let extensionData = extensions
    if (value) {
      extensionData.push(name)
    } else {
      extensionData = extensionData.filter(item => item !== name)
    }
    extensionData = [...new Set(extensionData)]
    !!extensionData.length && downloads.length ? setDisabled(false) : setDisabled(true)
    updateExtensions(extensionData)
  }

  function handleClose() {
    dispatch(actions.resetSelection())
    dispatch(actions.getConsultant("?sort=firstName"))
    handleVisibility(false)
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      title={<Translation label="yourcv.download.button" />}
    >
      <StyledDialogWrapper>
        <DialogContent dividers={true}>
          <Typography variant="h4" gutterBottom>
            <Translation label="yourcv.download.selection.label" />
          </Typography>
          {consultants &&
            consultants.map(user => (
              <div key={user.userId}>
                <div key={user.userId}>
                  <Typography variant="h3">{`${user.firstName} ${
                    user.lastName
                  }`}</Typography>
                  {user &&
                    Object.values(user.version!).map(vers => (
                      <div key={vers.versionId}>
                        <Checkbox
                          name={vers.versionId!.toString()}
                          onValueChange={handleSelection}
                          placeholderText={vers.name}
                          edited
                          checked={downloads.includes(
                            vers.versionId!.toString()
                          )}
                        />
                      </div>
                    ))}
                </div>
              </div>
            ))}
            <Typography variant="h4" gutterBottom>
              <Translation label="yourcv.download.extension.label" />
            </Typography>
            <Checkbox
              name="pdf"
              onValueChange={handleExtensions}
              placeholderText=".pdf"
              edited
              checked={extensions.includes("pdf")}
            />
            <Checkbox
              name="docx"
              onValueChange={handleExtensions}
              placeholderText=".docx"
              edited
              checked={extensions.includes("docx")}
            />
        </DialogContent>
        <DialogActions>
          <ActionWrapper>
            <Button variant="contained" color="inherit" onClick={handleCancel}>
              <Translation label="actions.cancel" />
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={disabled || isLoading}
            >
              {isLoading && <Spinner/>}
              <Translation label="yourcv.download.button.export" />
            </Button>
          </ActionWrapper>
        </DialogActions>
      </StyledDialogWrapper>
    </Dialog>
  )
}

export default DownloadCv
