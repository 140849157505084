import styled from "@emotion/styled"
import { empleoTheme } from "config/theme"
import Select from "react-select"

export const StyledSelect = styled(Select)`
  min-width: 50rem;
  height: 4.8rem;
  div:first-of-type {
    border-radius: 0.9rem;
    box-shadow: none;
    font-family: ${empleoTheme.typography.fontFamilySansSerif};
    font-size: 1.4rem;
  }
`

export const IconWrapper = styled.span`
  vertical-align: middle;
`
