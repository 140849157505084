import { GetTokenSilentlyOptions, GetUserOptions, RedirectLoginOptions } from "@auth0/auth0-spa-js"
import { auth0Client } from "./api"

export async function getToken(options?: GetTokenSilentlyOptions) {
  return await auth0Client.getTokenSilently(options)
}

export async function getUser(options?: GetUserOptions) {
  return await auth0Client.getUser(options)
}

export async function redirectToLogin(options?: RedirectLoginOptions) {
  return await auth0Client.loginWithRedirect(options)
}
