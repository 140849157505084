import { all, fork } from "redux-saga/effects"
import authSaga from "./auth"
import autoSuggestSaga from "./autosuggest"
import consultantsSaga from "./consultants"
import cvSaga from "./cv"
import educationSaga from "./education"
import experienceSaga from "./experience"
import invitationSaga from "./invitation"
import languageSaga from "./language"
import onboardingSaga from "./onboarding"
import permissionsSaga from "./permissions"
import projectSaga from "./project"
import referenceSaga from "./reference"
import skillSaga from "./skill"
import versionSaga from "./version"

const sagas = [
  authSaga,
  autoSuggestSaga,
  consultantsSaga,
  cvSaga,
  educationSaga,
  experienceSaga,
  languageSaga,
  onboardingSaga,
  projectSaga,
  referenceSaga,
  skillSaga,
  versionSaga,
  permissionsSaga,
  invitationSaga
]

export default function* root(): IterableIterator<any> {
  yield all(sagas.map((saga) => fork(saga)))
}
