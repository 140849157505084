import Consultant from "models/consultants"
import { UserSearch } from "models/user"
import { Action, Types } from "../actions/consultants"

export type ConsultantsState = {
  amountOfPages: number | undefined
  currentPage: number | undefined
  data: Consultant[] | undefined
  skill: string[] | undefined
  user: UserSearch[] | undefined
  activeCheckboxes: string[]
  errors: {}
  loading: boolean
}

const initialState: ConsultantsState = {
  activeCheckboxes: [],
  amountOfPages: undefined,
  currentPage: undefined,
  data: [],
  errors: {},
  loading: false,
  skill: undefined,
  user: undefined
}

export default (state = initialState, action: Action): ConsultantsState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.SET_CHECKBOXES:
      const { id } = action.payload
      const found = state.activeCheckboxes.find(e => e === id)
      return {
        ...state,
        activeCheckboxes: found
          ? state.activeCheckboxes.filter(e => e !== id)
          : [...state.activeCheckboxes, id]
      }

    case Types.RESET_SELECTION:
      return {
        ...state,
        activeCheckboxes: []
      }

    default:
      return state
  }
}
