import { Skill } from "models/cv"
import { Action, Types } from "../actions/skill"

export type SkillState = {
  skill: Skill | undefined
  data: Skill[] | undefined
  errors: object
  loading: boolean
}

const initialState: SkillState = {
  data: [],
  errors: {},
  loading: false,
  skill: undefined
}

export default (state = initialState, action: Action): SkillState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.DELETE_FAIL:
      return {
        ...state,
        errors: action.payload.errors
      }

    case Types.POST:
      return {
        ...state,
        loading: true
      }

    case Types.POST_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.POST_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.PATCH:
      return {
        ...state
      }

    case Types.PATCH_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.PATCH_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    default:
      return state
  }
}
