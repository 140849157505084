import * as api from "api/autosuggest"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "../actions/autosuggest"
import * as notificationActions from "../actions/notifications"

export function* getAutosuggestFlow(
  action: actions.GetAutosuggestAction
): IterableIterator<any> {
  try {
    const { query } = action.payload
    const response = yield call(api.getAutosuggest, query)
    yield put(actions.getAutosuggestSuccess(response.data))
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "getAutosuggestFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.getAutosuggestFail(e))
  }
}

export default function* autoSuggestSaga(): IterableIterator<any> {
  yield takeLatest(actions.Types.GET, getAutosuggestFlow)
}
