import styled from "@emotion/styled"
import SnackbarContent, {
  SnackbarContentProps
} from "@material-ui/core/SnackbarContent"
import Typography, { TypographyProps } from "@material-ui/core/Typography"
import { empleoTheme } from "config/theme"

type SCProps = {
  state: string
} & SnackbarContentProps

type TypoProps = {
  state: string
} & TypographyProps

const white = empleoTheme.colors.white

const variantColors: any = {
  error: {
    background: empleoTheme.colors.error.normal,
    color: white
  },
  info: {
    background: empleoTheme.colors.link.dark,
    color: white
  },
  success: {
    background: empleoTheme.colors.success,
    color: white
  }
}

const IconButtonWrapper: any = {
  display: "flex",
  justifyContent: "center"
}

export const StyledSnackbarContent = styled(SnackbarContent)<SCProps>`
  background-color: ${props =>
    props.state
      ? variantColors[props.state].background
      : empleoTheme.colors.grey[80]};
  box-shadow: none;
  height: 5rem;
  line-height: 5rem;
  display: flex;
  align-content: center;
`
export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
`
export const MessageSpan = styled.span`
  max-width: 73.6rem;
  font-size: 1.6rem;
`

export const MessageIcon = styled.span`
  width: ${IconButtonWrapper.width};
  display: ${IconButtonWrapper.display};
  justify-content: ${IconButtonWrapper.justifyContent};
  margin-right: 2rem;
  .SnackbarIcon {
    width: 5.5rem;
    height: 5.5rem;
    color: ${white};
  }
`

export const StyledTypography = styled(Typography)<TypoProps>`
  color: ${props => (props.state ? variantColors[props.state].color : white)};
  font-size: 1.6rem;
  font-weight: bold;
  line-height: normal;
`

export const ActionContainer = styled.div`
  width: ${IconButtonWrapper.width};
  display: ${IconButtonWrapper.display};
  justify-content: ${IconButtonWrapper.justifyContent};
`
