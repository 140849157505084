import styled from "@emotion/styled"
import { Stepper } from "@material-ui/core"
import { empleoTheme } from "config/theme"

export const StyledStepper = styled(Stepper)`
  padding: 0%;
  width: 100%;
  text {
    fill: ${empleoTheme.colors.white};
  }
`

export const ButtonWrapper = styled.div`
  margin-right: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
  min-width: 10rem;
`
