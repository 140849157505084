import idx from "idx"
import Cv, {
  Education,
  Experience,
  Language,
  Project,
  Skill,
  Version
} from "models/cv"
import State from "../state"

export const loading = (state: State): boolean => state.cv.loading
export const getCurrentCv = (state: State): Cv | undefined => state.cv.cv
export const getExperience = (state: State): Experience[] | undefined =>
  state.cv.cv && state.cv.cv.experience
export const getEducation = (state: State): Education[] | undefined =>
  state.cv.cv && state.cv.cv.education
export const getLanguages = (state: State): Language[] | undefined =>
  state.cv.cv && state.cv.cv.language
export const getProjects = (state: State): Project[] | undefined =>
  state.cv.cv && state.cv.cv.project
export const getSelectedLanguages = (state: State): (string | undefined)[] => {
  const languages = getLanguages(state) || []
  return languages.map(language => language && language.language)
}
export const getUserSkills = (state: State): Skill[] | null | undefined =>
  idx(state.cv.cv, _ => _.skill)
export const getVersions = (state: State): Version[] | null | undefined =>
  idx(state.cv.cv, _ => _.version)
export const getCvId = (state: State): string | null | undefined =>
  idx(state.cv.cv, _ => _.userId)
export const getOnboarding = (state: State): boolean | undefined | null =>
  idx(state.cv.cv, _ => _.onboarding)

export const getUserName = (state: State): string | undefined => {
  const { cv } = state.cv
  if (cv) return `${cv.firstName} ${cv.lastName}`
}
