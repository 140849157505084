export enum Types {
  CHANGE_TAB = "CHANGE_TAB"
}

export type Action = TabAction

export type TabAction = {
  payload: { id: number }
  type: Types.CHANGE_TAB
}

export function changeTab(id: number): TabAction {
  return {
    payload: { id },
    type: Types.CHANGE_TAB
  }
}
