import Autosuggest from "models/autosuggest"

export enum Types {
  GET = "AUTOSUGGEST_GET",
  GET_SUCCESS = "AUTOSUGGEST_GET_SUCCESS",
  GET_FAIL = "AUTOSUGGEST_GET_FAIL"
}

export type Action =
  | GetAutosuggestAction
  | GetAutosuggestSuccessAction
  | GetAutosuggestFailAction

export type GetAutosuggestAction = {
  payload: { query: string }
  type: Types.GET
}

export type GetAutosuggestSuccessAction = {
  payload: {
    data: Autosuggest | undefined
  }
  type: Types.GET_SUCCESS
}

export type GetAutosuggestFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export function getAutosuggest(query: string): GetAutosuggestAction {
  return {
    payload: { query },
    type: Types.GET
  }
}

export function getAutosuggestSuccess(
  data: Autosuggest | undefined
): GetAutosuggestSuccessAction {
  return {
    payload: { data },
    type: Types.GET_SUCCESS
  }
}

export function getAutosuggestFail(errors: object): GetAutosuggestFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}
