import { CircularProgress } from "@material-ui/core"
import React, { memo } from "react"
import { LoadingWrapper } from "./style"

function LoadingScreen() {
  return (
    <LoadingWrapper>
      <CircularProgress size={35} />
    </LoadingWrapper>
  )
}

export default memo(LoadingScreen)
