export enum Types {
  PATCH = "ONBOARDING_PATCH",
  PATCH_SUCCESS = "ONBOARDING_PATCH_SUCCESS",
  PATCH_FAIL = "ONBOARDING_PATCH_FAIL",
  INDICATOR_CHANGE = "ONBOARDING_INDICATOR_CHANGE"
}

export type Action =
  | PatchOnboardingAction
  | PatchOnboardingSuccessAction
  | PatchOnboardingFailAction
  | ChangeIndicatorAction

export type PatchOnboardingAction = {
  type: Types.PATCH
  payload: { id: string }
}

export type PatchOnboardingSuccessAction = {
  type: Types.PATCH_SUCCESS
}

export type PatchOnboardingFailAction = {
  type: Types.PATCH_FAIL
  payload: { errors: object }
}

export type ChangeIndicatorAction = {
  type: Types.INDICATOR_CHANGE
  payload: { placement: number }
}

export function patchOnboarding(id: string): PatchOnboardingAction {
  return {
    payload: { id },
    type: Types.PATCH
  }
}
export function patchOnboardingSuccess(): PatchOnboardingSuccessAction {
  return {
    type: Types.PATCH_SUCCESS
  }
}
export function patchOnboardingFail(errors: object): PatchOnboardingFailAction {
  return {
    payload: { errors },
    type: Types.PATCH_FAIL
  }
}

export function changeIndicator(placement: number): ChangeIndicatorAction {
  return {
    payload: { placement },
    type: Types.INDICATOR_CHANGE
  }
}
