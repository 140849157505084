import Autosuggest from "models/autosuggest"
import Consultant from "models/consultants"
import { Skill } from "models/cv"
import State from "../state"

export const getAutosuggest = (state: State): Autosuggest | undefined =>
  state.autosuggest.data
export const getAutosuggestSkills = (state: State): Skill | undefined =>
  state.autosuggest.data && state.autosuggest.data.skills

export const getAutosuggestNames = (state: State): Consultant | undefined =>
  state.autosuggest.data && state.autosuggest.data.names
export const isLoading = (state: State): boolean => state.autosuggest.loading
