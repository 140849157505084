import { Skill } from "models/cv"

export enum Types {
  GET = "SKILL_GET",
  GET_SUCCESS = "SKILL_GET_SUCCESS",
  GET_FAIL = "SKILL_GET_FAIL",
  POST = "SKILL_POST",
  POST_SUCCESS = "SKILL_POST_SUCCESS",
  POST_FAIL = "SKILL_POST_FAIL",
  PATCH = "SKILL_PATCH",
  PATCH_SUCCESS = "SKILL_PATCH_SUCCESS",
  PATCH_FAIL = "SKILL_PATCH_FAIL",
  DELETE = "SKILL_DELETE",
  DELETE_SUCCESS = "SKILL_DELETE_SUCCESS",
  DELETE_FAIL = "SKILL_DELETE_FAIL"
}

export type Action =
  | GetSkillAction
  | GetSkillSuccessAction
  | GetSkillFailAction
  | PostSkillAction
  | PostSkillSuccessAction
  | PostSkillFailAction
  | PatchSkillAction
  | PatchSkillSuccessAction
  | PatchSkillFailAction
  | DeleteSkillAction
  | DeleteSkillSuccessAction
  | DeleteSkillFailAction

export type GetSkillAction = {
  type: Types.GET
}

export type GetSkillSuccessAction = {
  payload: { data: Skill[] | undefined }
  type: Types.GET_SUCCESS
}

export type GetSkillFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export type PostSkillAction = {
  payload: { data: Skill }
  type: Types.POST
}

export type PostSkillSuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostSkillFailAction = {
  payload: { errors: object }
  type: Types.POST_FAIL
}

export type PatchSkillAction = {
  type: Types.PATCH
  payload: { data: Skill }
}

export type PatchSkillSuccessAction = {
  type: Types.PATCH_SUCCESS
}

export type PatchSkillFailAction = {
  type: Types.PATCH_FAIL
  payload: { errors: object }
}

export type DeleteSkillAction = {
  payload: { id: string | number }
  type: Types.DELETE
}

export type DeleteSkillSuccessAction = {
  type: Types.DELETE_SUCCESS
}

export type DeleteSkillFailAction = {
  payload: { errors: object }
  type: Types.DELETE_FAIL
}

export function getSkill(): GetSkillAction {
  return {
    type: Types.GET
  }
}

export function getSkillSuccess(
  data: Skill[] | undefined
): GetSkillSuccessAction {
  return {
    payload: { data },
    type: Types.GET_SUCCESS
  }
}

export function getSkillFail(errors: object): GetSkillFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function deleteSkill(id: string | number): DeleteSkillAction {
  return {
    payload: { id },
    type: Types.DELETE
  }
}

export function deleteSkillSuccess(): DeleteSkillSuccessAction {
  return {
    type: Types.DELETE_SUCCESS
  }
}

export function deleteSkillfail(errors: object): DeleteSkillFailAction {
  return {
    payload: { errors },
    type: Types.DELETE_FAIL
  }
}

export function postSkill(data: Skill): PostSkillAction {
  return {
    payload: { data },
    type: Types.POST
  }
}

export function postSkillSuccess(): PostSkillSuccessAction {
  return {
    type: Types.POST_SUCCESS
  }
}

export function postSkillFail(errors: object): PostSkillFailAction {
  return {
    payload: { errors },
    type: Types.POST_FAIL
  }
}

export function patchSkill(data: Skill): PatchSkillAction {
  return {
    payload: { data },
    type: Types.PATCH
  }
}
export function patchSkillSuccess(): PatchSkillSuccessAction {
  return {
    type: Types.PATCH_SUCCESS
  }
}
export function patchSkillFail(errors: object): PatchSkillFailAction {
  return {
    payload: { errors },
    type: Types.PATCH_FAIL
  }
}
