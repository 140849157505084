import { Education } from "models/cv"

export enum Types {
  POST = "EDUCATION_POST",
  POST_SUCCESS = "EDUCATION_POST_SUCCESS",
  POST_FAIL = "EDUCATION_POST_FAIL",
  DELETE = "EDUCATION_DELETE",
  DELETE_SUCCESS = "EDUCATION_DELETE_SUCCESS",
  DELETE_FAIL = "EDUCATION_DELETE_FAIL",
  PATCH = "EDUCATION_PATCH",
  PATCH_SUCCESS = "EDUCATION_PATCH_SUCCESS",
  PATCH_FAIL = "EDUCATION_PATCH_FAIL"
}

export type Action =
  | PostEducationAction
  | PostEducationSuccessAction
  | PostEducationFailAction
  | DeleteEducationByIdAction
  | DeleteEducationByIdSuccessAction
  | DeleteEducationByIdFailAction
  | PatchEducationAction
  | PatchEducationSuccessAction
  | PatchEducationFailAction

export type PostEducationAction = {
  payload: { data: Education }
  type: Types.POST
}

export type PostEducationSuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostEducationFailAction = {
  payload: { errors: object }
  type: Types.POST_FAIL
}

export type DeleteEducationByIdAction = {
  payload: { id: string, educationId: string }
  type: Types.DELETE
}

export type DeleteEducationByIdSuccessAction = {
  type: Types.DELETE_SUCCESS,
  payload: { data: Education | undefined }
}

export type DeleteEducationByIdFailAction = {
  payload: { errors: object }
  type: Types.DELETE_FAIL
}

export type PatchEducationAction = {
  type: Types.PATCH
  payload: { data: Education, educationId: string }
}

export type PatchEducationSuccessAction = {
  type: Types.PATCH_SUCCESS
  payload: { data: Education | undefined }
}

export type PatchEducationFailAction = {
  type: Types.PATCH_FAIL
  payload: { errors: object }
}

export function postEducationAction(data: Education): PostEducationAction {
  return {
    payload: { data },
    type: Types.POST
  }
}

export function postEducationSuccessAction(): PostEducationSuccessAction {
  return {
    type: Types.POST_SUCCESS
  }
}

export function postEducationFailAction(errors: object): PostEducationFailAction {
  return {
    payload: { errors },
    type: Types.POST_FAIL
  }
}

export function DeleteEducationByIdAction(id: string, educationId: string): DeleteEducationByIdAction {
  return {
    payload: { id, educationId },
    type: Types.DELETE
  }
}

export function DeleteEducationByIdSuccessAction(data: Education): DeleteEducationByIdSuccessAction {
  return {
    payload: { data },
    type: Types.DELETE_SUCCESS
  }
}

export function DeleteEducationByIdFailAction(errors: object): DeleteEducationByIdFailAction {
  return {
    payload: { errors },
    type: Types.DELETE_FAIL
  }
}

export function patchEducation(data: Education, educationId: string): PatchEducationAction {
  return {
    payload: { data, educationId },
    type: Types.PATCH
  }
}
export function patchEducationSuccess(data: Education): PatchEducationSuccessAction {
  return {
    payload: { data },
    type: Types.PATCH_SUCCESS
  }
}
export function patchEducationFail(errors: object): PatchEducationFailAction {
  return {
    payload: { errors },
    type: Types.PATCH_FAIL
  }
}
