import * as api from "api/onboarding"
import { call, put, takeLatest } from "redux-saga/effects"
import * as cvActions from "../actions/cv"
import * as notificationActions from "../actions/notifications"
import * as actions from "../actions/onboarding"

export function* patchOnboardingFlow(
  action: actions.PatchOnboardingAction
): IterableIterator<any> {
  try {
    yield call(api.patchOnboarding, action.payload.id)
    yield put(cvActions.getCVById(action.payload.id!))
    yield put(actions.patchOnboardingSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "patchOnboardingFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.patchOnboardingFail(e))
  }
}

export default function* onboardingSaga(): IterableIterator<any> {
  yield takeLatest(actions.Types.PATCH, patchOnboardingFlow)
}
