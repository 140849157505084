import { Button, Typography } from "@material-ui/core"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import DownloadCv from "components/DownloadCv"
import StickySearchbar from "components/Searchbar"
import React, { memo } from "react"
import { useSelector } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { RoutesEnum } from "routes"
import { getOnboarding, getUserName, loading } from "store/selectors/cv"
import OnboardingStepper from "../OnboardingStepper"
import Translation from "../Translation/Translation"
import { StyledHeader, StyledLink } from "./style"

function Header() {
  const isOnboarding = useSelector(getOnboarding)
  const [modalIsVisible, setModalVisibility] = React.useState(false)
  const userName = useSelector(getUserName)
  const isLoading = useSelector(loading)
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname.substring(1)
  const { id } = useParams<{ id: string }>()

  function handleGoBack() {
    history.goBack()
  }

  function handleToggleModal() {
    setModalVisibility(!modalIsVisible)
  }

  if (path !== RoutesEnum.Home && !isLoading) {
    return (
      <>
        <StyledHeader>
          {!isOnboarding ? (
            <>
              {path !== RoutesEnum.Consultants && path !== RoutesEnum.YourCV && (
                <StyledLink onClick={handleGoBack}>
                  <Typography>
                    <KeyboardArrowLeftIcon />
                    <Translation label="actions.back" />
                  </Typography>
                </StyledLink>
              )}
              <Typography variant="h1">
                {id ? (
                  userName
                ) : (
                  <Translation label={`navigationItem.${path}`} />
                )}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleToggleModal}
              >
                <Translation label="yourcv.download.button" />
              </Button>
            </>
          ) : (
            <>{path === RoutesEnum.YourCV && <OnboardingStepper />}</>
          )}
        </StyledHeader>
        {path === RoutesEnum.Consultants && <StickySearchbar />}
        {modalIsVisible && <DownloadCv handleVisibility={setModalVisibility} />}
      </>
    )
  }
  return null
}

export default memo(Header)
