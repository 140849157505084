import { Action, Types } from "../actions/tabs"

export type TabsState = {
  activeVersionTab: number | null
}

const initialState: TabsState = {
  activeVersionTab: null
}

export default (state = initialState, action: Action): TabsState => {
  switch (action.type) {
    case Types.CHANGE_TAB:
      return {
        ...state,
        activeVersionTab: action.payload.id
      }

    default:
      return state
  }
}
