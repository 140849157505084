import { Project } from "models/cv"

export enum Types {
  PATCH = "PROJECT_PATCH",
  PATCH_SUCCESS = "PROJECT_PATCH_SUCCESS",
  PATCH_FAIL = "PROJECT_PATCH_FAIL",
  POST = "PROJECT_POST",
  POST_SUCCESS = "PROJECT_POST_SUCCESS",
  POST_FAIL = "PROJECT_POST_FAIL",
  DELETE = "PROJECT_DELETE",
  DELETE_SUCCESS = "PROJECT_DELETE_SUCCESS",
  DELETE_FAIL = "PROJECT_DELETE_FAIL"
}

export type Action =
  | PostProjectAction
  | PostProjectSuccessAction
  | PostProjectFailAction
  | PatchProjectAction
  | PatchProjectSuccessAction
  | PatchProjectFailAction
  | DeleteProjectAction
  | DeleteProjectSuccessAction
  | DeleteProjectFailAction

export type PostProjectAction = {
  payload: { data: Project }
  type: Types.POST
}

export type PostProjectSuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostProjectFailAction = {
  payload: { errors: object }
  type: Types.POST_FAIL
}

export type PatchProjectAction = {
  payload: { data: Project }
  type: Types.PATCH
}

export type PatchProjectSuccessAction = {
  type: Types.PATCH_SUCCESS
}

export type PatchProjectFailAction = {
  payload: { errors: object }
  type: Types.PATCH_FAIL
}

export type DeleteProjectAction = {
  payload: { languageId: string }
  type: Types.DELETE
}

export type DeleteProjectSuccessAction = {
  type: Types.DELETE_SUCCESS
}

export type DeleteProjectFailAction = {
  payload: { errors: object }
  type: Types.DELETE_FAIL
}

export function postProject(data: Project): PostProjectAction {
  return {
    payload: { data },
    type: Types.POST
  }
}

export function postProjectSuccess(): PostProjectSuccessAction {
  return {
    type: Types.POST_SUCCESS
  }
}

export function postProjectFail(errors: object): PostProjectFailAction {
  return {
    payload: { errors },
    type: Types.POST_FAIL
  }
}

export function patchProject(data: Project): PatchProjectAction {
  return {
    payload: { data },
    type: Types.PATCH
  }
}

export function patchProjectSuccess(): PatchProjectSuccessAction {
  return {
    type: Types.PATCH_SUCCESS
  }
}

export function patchProjectFail(errors: object): PatchProjectFailAction {
  return {
    payload: { errors },
    type: Types.PATCH_FAIL
  }
}

export function deleteProject(languageId: string): DeleteProjectAction {
  return {
    payload: { languageId },
    type: Types.DELETE
  }
}

export function deleteProjectSuccess(): DeleteProjectSuccessAction {
  return {
    type: Types.DELETE_SUCCESS
  }
}

export function deleteProjectFail(errors: object): DeleteProjectFailAction {
  return {
    payload: { errors },
    type: Types.DELETE_FAIL
  }
}
