import { Language } from "models/cv"

export enum Types {
  PATCH = "LANGUAGE_PATCH",
  PATCH_SUCCESS = "LANGUAGE_PATCH_SUCCESS",
  PATCH_FAIL = "LANGUAGE_PATCH_FAIL",
  POST = "LANGUAGE_POST",
  POST_SUCCESS = "LANGUAGE_POST_SUCCESS",
  POST_FAIL = "LANGUAGE_POST_FAIL",
  DELETE = "LANGUAGE_DELETE",
  DELETE_SUCCESS = "LANGUAGE_DELETE_SUCCESS",
  DELETE_FAIL = "LANGUAGE_DELETE_FAIL"
}

export type Action =
  | PostLanguageAction
  | PostLanguageSuccessAction
  | PostLanguageFailAction
  | PatchLanguageAction
  | PatchLanguageSuccessAction
  | PatchLanguageFailAction
  | DeleteLanguageAction
  | DeleteLanguageSuccessAction
  | DeleteLanguageFailAction

export type PostLanguageAction = {
  payload: { data: Language }
  type: Types.POST
}

export type PostLanguageSuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostLanguageFailAction = {
  payload: { errors: object }
  type: Types.POST_FAIL
}

export type PatchLanguageAction = {
  payload: { data: Language }
  type: Types.PATCH
}

export type PatchLanguageSuccessAction = {
  type: Types.PATCH_SUCCESS
}

export type PatchLanguageFailAction = {
  payload: { errors: object }
  type: Types.PATCH_FAIL
}

export type DeleteLanguageAction = {
  payload: { languageId: string }
  type: Types.DELETE
}

export type DeleteLanguageSuccessAction = {
  type: Types.DELETE_SUCCESS
}

export type DeleteLanguageFailAction = {
  payload: { errors: object }
  type: Types.DELETE_FAIL
}

export function postLanguage(data: Language): PostLanguageAction {
  return {
    payload: { data },
    type: Types.POST
  }
}

export function postLanguageSuccess(): PostLanguageSuccessAction {
  return {
    type: Types.POST_SUCCESS
  }
}

export function postLanguageFail(errors: object): PostLanguageFailAction {
  return {
    payload: { errors },
    type: Types.POST_FAIL
  }
}

export function patchLanguage(data: Language): PatchLanguageAction {
  return {
    payload: { data },
    type: Types.PATCH
  }
}

export function patchLanguageSuccess(): PatchLanguageSuccessAction {
  return {
    type: Types.PATCH_SUCCESS
  }
}

export function patchLanguageFail(errors: object): PatchLanguageFailAction {
  return {
    payload: { errors },
    type: Types.PATCH_FAIL
  }
}

export function deleteLanguage(languageId: string): DeleteLanguageAction {
  return {
    payload: { languageId },
    type: Types.DELETE
  }
}

export function deleteLanguageSuccess(): DeleteLanguageSuccessAction {
  return {
    type: Types.DELETE_SUCCESS
  }
}

export function deleteLanguageFail(errors: object): DeleteLanguageFailAction {
  return {
    payload: { errors },
    type: Types.DELETE_FAIL
  }
}
