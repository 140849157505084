import MUIDrawer from "@material-ui/core/Drawer"
import { withStyles } from "@material-ui/core/styles"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"

import classNames from "classnames"
import Divider from "components/Divider"
import React, { useState } from "react"
import { withRouter } from "react-router"
import Logo from "../Logo/Logo"
import { ClickArrow, LogoHeader, LogoWrap, styles } from "./Navigation.style"
import { NavigationProps } from "./Navigation.types"
import NavigationItems from "./NavigationItems"

function Navigation({ classes, history }: NavigationProps) {
  const [open, toggleDrawer] = useState(false)

  function handleDrawerClose() {
    toggleDrawer(!open)
  }

  function handleHomeClick() {
    history.push("/your-cv")
  }

  return (
    <MUIDrawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
      open={open}
    >
      <LogoHeader>
        <LogoWrap onClick={handleHomeClick}>
          <Logo
            full={open}
            width={open ? "13rem" : "2.5rem"}
            height={open ? "3.5rem" : "2.5rem"}
          />
        </LogoWrap>
        <ClickArrow mirror={open} onClick={handleDrawerClose}>
          <ChevronRightIcon />
        </ClickArrow>
      </LogoHeader>

      <Divider />

      <NavigationItems />
    </MUIDrawer>
  )
}

export default withRouter(withStyles(styles as any, { withTheme: true })(Navigation))
