import { deleteProject, patchProject, postProject } from "api/cv"
import { call, put, select, takeLatest } from "redux-saga/effects"
import * as cvActions from "../actions/cv"
import * as notificationActions from "../actions/notifications"
import * as actions from "../actions/project"
import * as CVSelectors from "../selectors/cv"

export function* postProjectFlow(
  action: actions.PostProjectAction
): IterableIterator<any> {
  try {
    const { data } = action.payload
    const userId = yield select(CVSelectors.getCvId)
    yield call(postProject, data, userId)
    yield put(cvActions.getCVById(userId!))
    yield put(actions.postProjectSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "postProjectFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.postProjectFail(e))
  }
}

export function* deleteProjectFlow(
  action: actions.DeleteProjectAction
): IterableIterator<any> {
  try {
    const { languageId } = action.payload
    const userId = yield select(CVSelectors.getCvId)
    yield call(deleteProject, userId, languageId)
    yield put(cvActions.getCVById(userId))
    yield put(actions.deleteProjectSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "deleteProjectFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.deleteProjectFail(e))
  }
}

export function* patchProjectFlow(
  action: actions.PatchProjectAction
): IterableIterator<any> {
  try {
    const { data } = action.payload
    const projectId = data.projectId
    delete data.projectId
    const userId = yield select(CVSelectors.getCvId)
    if (userId && projectId) {
      yield call(patchProject, data, userId, projectId)
      yield put(cvActions.getCVById(userId))
    }
    yield put(actions.patchProjectSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "patchProjectFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.patchProjectFail(e))
  }
}

export default function* projectSaga(): IterableIterator<any> {
  yield takeLatest(actions.Types.PATCH, patchProjectFlow)
  yield takeLatest(actions.Types.POST, postProjectFlow)
  yield takeLatest(actions.Types.DELETE, deleteProjectFlow)
}
