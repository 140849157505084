import styled from "@emotion/styled"

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex: 1;
`
export const StyledDialogWrapper = styled.div`
  .MuiDialogActions-root {
    padding: 0px;
    position: relative;
    top: 24px;
  }

  .MuiDialogContent-dividers {
    padding: 20px 0 8px 0;
    border-bottom: none;
    margin-top: 12px;
    margin-bottom: -24px;
  }
  .MuiDialogActions-root {
    padding: 0 !important;
  }
`
