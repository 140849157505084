import { withAuthenticationRequired } from "@auth0/auth0-react"
import Layout from "components/Layout"
import React from "react"
import { Route, RouteComponentProps, RouteProps, Switch } from "react-router-dom"
import routes from "./routes"

export type RouteObject = RouteProps & {
  authorized: boolean;
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const ProtectedRoute = ({ authorized, component, path, exact }: RouteObject) => (
  <Route path={path} exact={exact} component={authorized ? withAuthenticationRequired(component) : component} />
)

const Router = (): JSX.Element => {
  return (
    <Switch>
      {routes.map(({ authorized, component: Component, path, exact }: RouteObject, id) => {
        const RenderedLayout = (componentProps: RouteComponentProps) => (
          <Layout>
            <Component {...componentProps} />
          </Layout>
        )

        return <ProtectedRoute key={id} authorized={authorized} component={RenderedLayout} path={`/${path}`} exact={exact} />
      })}
    </Switch>
  )
}

export default Router
