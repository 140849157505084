import {
  Button,
  DialogActions,
  DialogContent,
  Typography
} from "@material-ui/core"
import { cvApi } from "api/api"
import Dialog from "components/Dialog"
import Checkbox from "components/Form/Checkbox"
import { Spinner } from "components/Spinner/Spinner"
import { Version } from "models/cv"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as actions from "store/actions/cv"
import * as selectors from "store/selectors/cv"
import Translation from "../Translation/Translation"
import { ActionWrapper, StyledDialogWrapper } from "./style"

type Props = {
  handleVisibility: (value: boolean) => void
  consultantId?: string
}

function DownloadCv({ handleVisibility, consultantId }: Props) {
  const dispatch = useDispatch()
  const versions = useSelector(selectors.getVersions)
  const initialDownloads: any[] = []
  const [downloads, updateDownloads] = React.useState(initialDownloads)
  const [extensions, updateExtensions] = React.useState<string[]>([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [disabled, setDisabled] = React.useState(true)

  useEffect(() => {
    if (consultantId) {
      dispatch(actions.getCVById(consultantId))
    }
  }, [consultantId, dispatch])

  function handleSelection(value: boolean, name: string) {
    let versionData: any[] = downloads || []
    if (value) {
      versionData.push(name)
    } else {
      versionData = versionData.filter(item => item !== name)
    }
    versionData = [...new Set(versionData)]
    !!versionData.length && extensions.length ? setDisabled(false) : setDisabled(true)
    updateDownloads(versionData)
  }

  function handleExtensions(value: boolean, name: string) {
    let extensionData = extensions
    if (value) {
      extensionData.push(name)
    } else {
      extensionData = extensionData.filter(item => item !== name)
    }
    extensionData = [...new Set(extensionData)]
    !!extensionData.length && downloads.length ? setDisabled(false) : setDisabled(true)
    updateExtensions(extensionData)
  }

  function handleCancel() {
    updateDownloads(initialDownloads)
    handleVisibility(false)
  }

  function handleSubmit() {
    if (!!downloads.length) {
      getDownload(downloads)
    }
  }

  function getDownload(data: any) {
    const theData = { versionIds: data.map((item: any) => parseInt(item, 10)), fileTypes: extensions }
    setIsLoading(true)
    cvApi("/document", {
      data: theData,
      method: "POST",
      responseType: "blob"
    }).then(responseData => {
      const { readyState, status } = responseData.request
      const { headers } = responseData
      if (readyState === 4 && status === 200) {
        setIsLoading(false)
        download(headers.filename, responseData.data, headers["content-type"])
        handleCancel()
      }
    })
  }

  function download(filename: any, data: any, fileType: any) {
    const blob = new Blob([data], { type: fileType })
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")

    a.href = url
    a.download = filename || "download"
    a.style.display = "none"

    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url)
        a.removeEventListener("click", clickHandler)
      }, 150)
    }
    a.addEventListener("click", clickHandler, false)
    a.click()
  }

  return (
    <Dialog
      open
      onClose={() => handleVisibility(false)}
      title={<Translation label="yourcv.download.button" />}
    >
      <StyledDialogWrapper>
        <DialogContent dividers={true}>
          <Typography variant="h4" gutterBottom>
            <Translation label="yourcv.download.selection.label" />
          </Typography>
          {versions &&
            versions.map((version: Version) => {
              return (
                <div key={version.versionId}>
                  <Checkbox
                    name={version.versionId!.toString()}
                    onValueChange={handleSelection}
                    placeholderText={version.name}
                    edited
                    checked={downloads.includes(version.versionId!.toString())}
                  />
                </div>
              )
            })}
            <Typography variant="h4" gutterBottom>
              <Translation label="yourcv.download.extension.label" />
            </Typography>
            <Checkbox
              name="pdf"
              onValueChange={handleExtensions}
              placeholderText=".pdf"
              edited
              checked={extensions.includes("pdf")}
            />
            <Checkbox
              name="docx"
              onValueChange={handleExtensions}
              placeholderText=".docx"
              edited
              checked={extensions.includes("docx")}
            />
        </DialogContent>
        <DialogActions>
          <ActionWrapper>
            <Button variant="contained" color="inherit" onClick={handleCancel}>
              <Translation label="actions.cancel" />
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={disabled || isLoading}
            >
              {isLoading && <Spinner/>}
              <Translation label="yourcv.download.button.export" />
            </Button>
          </ActionWrapper>
        </DialogActions>
      </StyledDialogWrapper>
    </Dialog>
  )
}

export default DownloadCv
