import { Fade, IconButton, Menu, MenuItem } from "@material-ui/core"
import SortIcon from "@material-ui/icons/Sort"
import React, { memo } from "react"
import { RouteComponentProps, withRouter } from "react-router"
import Translation from "../Translation/Translation"

enum Sort {
  Relevance = "relevance",
  FirstName = "firstName",
  LastName = "lastName"
  // Status = "status"
}

function Sorting({
  history,
  location,
  location: { search }
}: RouteComponentProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const p = new URLSearchParams(search)

  function toggleSortMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  function handleSort(value: Sort) {
    p.set("sort", value.toString())
    setAnchorEl(null)
    history.push(`${location.pathname}?${p.toString()}`)
  }

  return (
    <div>
      <IconButton aria-controls="fade-menu" onClick={toggleSortMenu}>
        <SortIcon />
      </IconButton>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={toggleSortMenu}
        TransitionComponent={Fade}
      >
        {Object.values(Sort).map((s: Sort) => (
          <MenuItem
            key={s}
            selected={p.get("sort") === s}
            data-value={s}
            disabled={s === Sort.Relevance && p.getAll("search").length === 0}
            onClick={() => handleSort(s)}
          >
            <Translation label={`consultants.sorting.${s}`} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default withRouter(memo(Sorting))
