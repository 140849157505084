import ColorUtil from "color"
import { Base64 } from "js-base64"
import Cv from "models/cv"
import moment from "moment"
import { isSafari } from "react-device-detect"

export function isAccessTokenExpired(accessToken: any): boolean {
  const expirationDate = accessToken.exp * 1000
  return isExpired(expirationDate)
}

export function isExpired(date: number, today = Date.now() - 2000) {
  return date <= today
}

export function parseToken(t: string) {
  if (typeof t !== "string") {
    return
  }
  const base64Url = t.split(".")[1]
  const base64 = base64Url.replace("-", "+").replace("_", "/")
  return JSON.parse(Base64.decode(base64))
}

export function nameInitials(firstname: string, lastname: string) {
  const short = firstname.replace(/(.{7}).+/, "$1")
  const initials = lastname.replace(/(.{1}).+/, "$1.")

  return `${short} ${initials}`
}

export const brighten = (color: string, brightness: number) => {
  return ColorUtil({
    h: ColorUtil(color)
      .hsv()
      .round()
      .object().h,
    s: ColorUtil(color)
      .hsv()
      .round()
      .object().s,
    v:
      ColorUtil(color)
        .hsv()
        .round()
        .object().v + brightness
  }).string()
}

export const opacify = (color: string, opacity: number) => {
  return ColorUtil([
    ColorUtil(color).red(),
    ColorUtil(color).green(),
    ColorUtil(color).blue(),
    opacity
  ]).string()
}

export const formatDateForApi = (value: string) => {
  if (
    isSafari &&
    value &&
    typeof value === "string" &&
    moment(value, "DD-MM-YYYY").isValid() &&
    value.indexOf("_") === -1
  ) {
    return new Date(moment(value, "DD-MM-YYYY").toString())
  } else {
    return value
  }
}

export const returnDatePickerError = (
  fromFormatDate?: string,
  toFormatDate?: string
) => {
  return (
    toFormatDate &&
    fromFormatDate &&
    (fromFormatDate >= toFormatDate ? "error.datepicker" : undefined)
  )
}

export const formatValue = (
  value: string | undefined,
  format: string,
  type?: string
) => {
  if (type === "date") {
    if (moment(value).isValid()) {
      return moment(value).format(format)
    }
    return ""
  }
  return value
}

export const isEmptyObject = (obj: object) =>
  Object.keys(obj).length === 0 && obj.constructor === Object

export function transformCv(cv: Cv) {
  const transformedCV = {
    ...cv,
    city: cv.geoLocation && cv.geoLocation.city,
    country: cv.geoLocation && cv.geoLocation.country,
    dateOfBirth: cv.dateOfBirth,
    drivingLicense: cv.drivingLicense,
    email: cv.email,
    firstName: cv.firstName,
    hobbies: cv.hobbies,
    lastName: cv.lastName,
    nationality: cv.nationality,
    phoneNumber: cv.phoneNumber,
    profilePicture: cv.profilePicture,
    streetAndNumber: cv.geoLocation && cv.geoLocation.streetAndNumber,
    webLinks: cv.webLinks,
    zipcode: cv.geoLocation && cv.geoLocation.zipcode
  }

  return transformedCV
}

export function removeForbiddenValuesFromObject(obj: any) {
  const forbiddenKeys = [
    "skill",
    "userId",
    "locationId",
    "globalUpdatedAt",
    "createdAt",
    "updatedAt",
    "geoLocation",
    "usedSkillInProject"
  ]

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const item = obj[key]
      const isNull = item === null
      const isUndefined = item === undefined
      const isEmptyArr = !isUndefined && !isNull
      const isForbiddenKey = forbiddenKeys.includes(key)

      if (isNull || isEmptyArr || isForbiddenKey) {
        delete obj[key]
      }
    }
  }

  return obj
}
