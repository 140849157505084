import * as api from "api/cv"
import { call, put, select, takeLatest } from "redux-saga/effects"
import * as cvActions from "../actions/cv"
import * as notificationActions from "../actions/notifications"
import * as actions from "../actions/skill"
import * as CVSelectors from "../selectors/cv"

export function* getSkillFlow(
  action: actions.GetSkillAction
): IterableIterator<any> {
  try {
    const id = yield select(CVSelectors.getCvId)
    const response = yield call(api.getSkills, id!)
    yield put(actions.getSkillSuccess(response && response.data!))
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "getSkillFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.getSkillFail(e))
  }
}

export function* deleteSkillFlow(
  action: actions.DeleteSkillAction
): IterableIterator<any> {
  try {
    const { id } = action.payload
    const userId = yield select(CVSelectors.getCvId)
    yield call(api.deleteSkill, userId, id)
    yield put(actions.getSkill())
    yield put(cvActions.getCVById(userId!))
    yield put(actions.deleteSkillSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "deleteSkillFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.deleteSkillfail(e))
  }
}

export function* postSkillFlow(
  action: actions.PostSkillAction
): IterableIterator<any> {
  try {
    const { data } = action.payload
    const userId = yield select(CVSelectors.getCvId)
    yield call(api.postSkill, data, userId)
    yield put(cvActions.getCVById(userId!))
    yield put(actions.getSkill())
    yield put(actions.postSkillSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "postSkillFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.postSkillFail(e))
  }
}

export function* patchSkillFlow(
  action: actions.PatchSkillAction
): IterableIterator<any> {
  try {
    const skill = action.payload.data
    const skillId = skill.skillId
    delete skill.skillId
    const userId = yield select(CVSelectors.getCvId)
    yield call(api.patchSkill, skill, skillId!, userId)
    yield put(cvActions.getCVById(userId!))
    yield put(actions.getSkill())
    yield put(actions.patchSkillSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "patchSkillFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.patchSkillFail(e))
  }
}

export default function* skillSaga(): IterableIterator<any> {
  yield takeLatest(actions.Types.GET, getSkillFlow)
  yield takeLatest(actions.Types.DELETE, deleteSkillFlow)
  yield takeLatest(actions.Types.POST, postSkillFlow)
  yield takeLatest(actions.Types.PATCH, patchSkillFlow)
}
