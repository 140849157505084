import IconButton from "@material-ui/core/IconButton"
import Snackbar from "@material-ui/core/Snackbar"
import Check from "@material-ui/icons/Check"
import Cross from "@material-ui/icons/Close"
import Error from "@material-ui/icons/Error"
import Info from "@material-ui/icons/Info"
import React, { Dispatch, memo } from "react"
import { connect } from "react-redux"
import { Action } from "redux"
import * as actions from "store/actions/notifications"
import Translation from "../Translation/Translation"
import {
  ActionContainer,
  MessageContainer,
  MessageIcon,
  MessageSpan,
  StyledSnackbarContent,
  StyledTypography
} from "./style"

type DispatchProps = {
  hide: (id: string) => void
}

type Props = {
  hide: (id: string) => void
  id: string
  message: string
  values?: {}
  state: string
}

function Notification(props: Props) {
  const { hide, id, message, values, state } = props

  const handleOnClose = () => {
    hide(id)
  }

  const iconVariant = (code: string) => {
    switch (code) {
      case "success":
        return <Check />
      case "error":
        return <Error />
      case "info":
        return <Info />
      default:
        return
    }
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open
      autoHideDuration={3500}
      onClose={handleOnClose}
      ContentProps={{
        "aria-describedby": id
      }}
    >
      <StyledSnackbarContent
        message={
          <MessageContainer>
            <MessageIcon>{iconVariant(state)}</MessageIcon>
            <MessageSpan>
              <StyledTypography state={state}>
                {values && Object.keys(values).length > 0 ? (
                  <Translation label={message} values={values} />
                ) : (
                  <Translation label={message} />
                )}
              </StyledTypography>
            </MessageSpan>
          </MessageContainer>
        }
        state={state}
        action={
          <ActionContainer>
            <IconButton
              key={`close-${id}`}
              aria-label="Close"
              color="inherit"
              onClick={handleOnClose}
            >
              <Cross />
            </IconButton>
          </ActionContainer>
        }
      />
    </Snackbar>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    hide: (id: string) => {
      dispatch(actions.hide(id))
    }
  }
}

export default connect(
  null,
  mapDispatchToProps
)(memo(Notification))
