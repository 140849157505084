import Consultant from "models/consultants"
import { UserSearch } from "models/user"
import State from "../state"

export const getConsultants = (state: State): Consultant[] | undefined =>
  state.consultant.data
export const getCurrentPage = (state: State): number | undefined =>
  state.consultant.currentPage
export const getAmountOfPages = (state: State): number | undefined =>
  state.consultant.amountOfPages
export const consultantsIsLoading = (state: State): boolean =>
  state.consultant.loading
export const getSkills = (state: State): string[] | undefined =>
  state.consultant.skill
export const getUsers = (state: State): UserSearch[] | undefined =>
  state.consultant.user
export const getActiveCheckboxes = (state: State): string[] =>
  state.consultant.activeCheckboxes
