import styled from "@emotion/styled"
import { empleoTheme } from "config/theme"

export const StyledSpinner = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: ${empleoTheme.colors.background} ${empleoTheme.colors.background} transparent ${empleoTheme.colors.background};
  animation: rotate 1.2s linear infinite;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
