export enum Types {
  TOGGLE = "DRAWER_TOGGLE"
}

export type Action = ToggleAction

export type ToggleAction = {
  type: Types.TOGGLE
}

export function toggle(): ToggleAction {
  return {
    type: Types.TOGGLE
  }
}
