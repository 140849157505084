import SearchIcon from "@material-ui/icons/Search"
import React, { memo } from "react"
import { useIntl } from "react-intl"
import { CreatableProps } from "react-select/creatable"
import { IconWrapper, StyledSelect } from "./style"

type Option = {
  label: string
  value: string | number
}

type Props = {
  selectText: string
  edited?: boolean
  isDisabled?: boolean
  value?: string | null
  isLoading?: boolean
  placeholderText?: string
  options: Option[]
  optional?: boolean
  onValueChange: (value: string) => void
  onInputChange: (value: string) => void
  onEnter: (value: string) => void
} & CreatableProps<any>
function MultiSelect({
  selectText,
  isDisabled,
  value,
  options,
  onInputChange,
  onValueChange,
  onEnter
}: Props): JSX.Element {
  const { messages } = useIntl()
  const selectedOption = options.find(e => e.value === value) || null
  const placeHolderText = messages[(selectText && selectText) || ""]

  function handleChange(option: Option) {
    if (option) {
      onValueChange(option.value.toString())
    } else {
      onValueChange(option)
    }
  }

  function handleKeyCodes(input: {
    keyCode: number
    target: { value: string }
  }) {
    const {
      keyCode,
      target: { value: val }
    } = input

    if (keyCode === 13) onEnter(val)
  }

  return (
    <StyledSelect
      placeholder={
        <>
          <IconWrapper>
            <SearchIcon color="disabled" />
          </IconWrapper>
          &nbsp;
          {placeHolderText}
        </>
      }
      isClearable
      isDisabled={isDisabled}
      options={options}
      value={value && selectedOption}
      onChange={handleChange}
      onInputChange={onInputChange}
      onKeyDown={handleKeyCodes}
    />
  )
}

export default memo(MultiSelect)
