import INotification from "models/notification"
import React, { memo } from "react"
import { connect } from "react-redux"
import * as selectors from "store/selectors/notifications"
import IState from "store/state"
import Notification from "./Notification"

type StateProps = {
  notifications: INotification[]
}

function Notifications({ notifications }: StateProps): any {
  return notifications.map((notification: INotification) => {
    return (
      <Notification
        id={notification.id}
        key={notification.id}
        message={notification.message}
        values={notification.values}
        state={notification.variant}
      />
    )
  })
}

const mapStateToProps = (state: IState): StateProps => {
  return {
    notifications: selectors.getNotifications(state)
  }
}

export default connect(mapStateToProps)(memo(Notifications))
