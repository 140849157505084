import { Invitation } from "models"

export enum InvitationActionTypes {
  SEND_INVITATION_REQUEST = "SEND_INVITATION_REQUEST",
  SEND_INVITATION_SUCCESS = "SEND_INVITATION_SUCCESS",
  SEND_INVITATION_FAILURE = "SEND_INVITATION_FAILURE"
}

export interface SendInvitationRequestAction {
  type: typeof InvitationActionTypes.SEND_INVITATION_REQUEST
  payload: Invitation
}

export interface SendInvitationSuccessAction {
  type: typeof InvitationActionTypes.SEND_INVITATION_SUCCESS
}

export interface SendInvitationFailureAction {
  type: typeof InvitationActionTypes.SEND_INVITATION_FAILURE
  payload: {
    error: string
  }
}

export const sendInvitationRequest = (invitation: Invitation): SendInvitationRequestAction => ({
  type: InvitationActionTypes.SEND_INVITATION_REQUEST,
  payload: invitation
})

export const sendInvitationSuccess = (): SendInvitationSuccessAction => ({
  type: InvitationActionTypes.SEND_INVITATION_SUCCESS
})

export const sendInvitationFailure = (error: string): SendInvitationFailureAction => ({
  type: InvitationActionTypes.SEND_INVITATION_FAILURE,
  payload: { error }
})
