import styled from "@emotion/styled"
import Checkbox from "@material-ui/core/Checkbox"
import { empleoTheme } from "config/theme"

export const StyledInput = styled(Checkbox)`
  height: 4rem;
  font-size: 1.4rem;
`

export const StyledValue = styled.div<any>`
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${empleoTheme.colors.body};
  font-style: ${props => !props.isNotEmpty && "italic"};
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;
  }
`
