import { Button, Step, StepLabel } from "@material-ui/core"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { changeIndicator, patchOnboarding } from "store/actions/onboarding"
import * as selectors from "store/selectors/cv"
import { getOnboardingPlacement } from "store/selectors/onboarding"
import Translation from "../Translation/Translation"
import { ButtonWrapper, StyledStepper } from "./style"

export default function OnboardingStepper(): JSX.Element {
  const dispatch = useDispatch()

  const versions = useSelector(selectors.getVersions)
  const languages = useSelector(selectors.getLanguages)
  const experiences = useSelector(selectors.getExperience)
  const skills = useSelector(selectors.getUserSkills)
  const education = useSelector(selectors.getEducation)
  const projects = useSelector(selectors.getProjects)
  const id = useSelector(selectors.getCvId)
  const placement = useSelector(getOnboardingPlacement)

  const hasRoles = () => (versions && versions.length > 0) || false

  function hasValidCvInformation() {
    const isValid = [languages, experiences, skills, education, projects].map(
      arr => arr && arr.length > 0
    )
    return isValid.every(val => val)
  }

  function handleClick() {
    dispatch(patchOnboarding(id!))
    dispatch(changeIndicator(0))
  }
  function handleNext() {
    dispatch(changeIndicator(placement + 1))
  }

  const valid = hasValidCvInformation() && hasRoles()
  const validInformation = hasValidCvInformation()
  return (
    <>
      <StyledStepper alternativeLabel activeStep={placement}>
        <Step
          completed={validInformation}
          onClick={() => dispatch(changeIndicator(0))}
        >
          <StepLabel>
            <Translation label="yourcv.personal.onboarding.step1" />
          </StepLabel>
        </Step>
        <Step
          completed={hasRoles()}
          onClick={() => validInformation && dispatch(changeIndicator(1))}
        >
          <StepLabel>
            <Translation label="yourcv.personal.onboarding.step2" />
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Translation label="yourcv.personal.onboarding.step3" />
          </StepLabel>
        </Step>
      </StyledStepper>
      <ButtonWrapper>
        {placement === 1 ? (
          <Button
            disabled={!valid}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            <Translation label="actions.submit" />
          </Button>
        ) : (
            <Button
              disabled={!hasValidCvInformation()}
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              <Translation label="actions.next" />
            </Button>
          )}
      </ButtonWrapper>
    </>
  )
}
