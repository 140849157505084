import { getUserValidation } from "api"
import { getUser } from "api/oauth"
import { push } from "connected-react-router"
import Cv from "models/cv"
import User from "models/user"
import { call, put, takeLatest } from "redux-saga/effects"
import { AuthActionTypes, FindOrCreateUserAction } from "store/actions/auth"
import { getPermissions } from "store/actions/permissions"

export function* findOrCreateUser(
  action: FindOrCreateUserAction
): IterableIterator<any> {
  try {
    const user: Partial<User> | undefined = yield call(getUser)
    if (!user) throw Error("No user found")
    const { email, given_name, family_name, picture } = user as Partial<User>
    const userPartial = {
      email: email as string,
      firstName: given_name as string,
      lastName: family_name as string,
      profilePicture: picture as string
    } as Cv

    yield call(getUserValidation, userPartial)
    yield put(getPermissions())
    yield put(push("/your-cv"))
  } catch (error) {
    console.log(error)
    console.error("Error: could not find nor create user")
  }
}

export default function* cvSaga(): IterableIterator<any> {
  yield takeLatest(AuthActionTypes.FIND_OR_CREATE_USER, findOrCreateUser)
}
