import { useAuth0 } from "@auth0/auth0-react"
import { ExitToApp } from "@material-ui/icons"
import GroupOutlined from "@material-ui/icons/GroupOutlined"
import SendOutlined from "@material-ui/icons/SendOutlined"
import { useTrans } from "Hooks/useTrans"
import React from "react"
import { RoutesEnum } from "routes"
import { MenuWrapper, UserImage } from "./Navigation.style"
import NavigationLink, { NavigationLinkProps } from "./NavigationLink"

function NavigationItems() {
  const { user } = useAuth0()
  const { t } = useTrans()

  const items: NavigationLinkProps[] = [
    {
      icon: <GroupOutlined />,
      label: t("navigationItem.consultants"),
      permissions: ["read:consultant:list"],
      to: `/${RoutesEnum.Consultants}`
    },
    {
      icon: <SendOutlined />,
      label: t("navigationItem.invitation"),
      permissions: ["read:consultant:list"],
      to: `/${RoutesEnum.Invitation}`
    }
    // Later in PR toevoegen
    // {
    //   icon: <DescriptionOutlinedIcon />,
    //   label: "navigationItem.skillmanager",
    //   permissions: ["read:skillManager"],
    //   to: `/${RoutesEnum.skillmanager}`
    // }
  ]

  return (
    <MenuWrapper>
        <div>
            { user && (
                <NavigationLink
                    to={`/${RoutesEnum.YourCV}`}
                    label={`${user.name}`}
                    icon={<UserImage src={user.picture} />}
                    divider
                    />
                )
            }

            {items.map((props, index) => (
                <React.Fragment key={index}>
                <NavigationLink divider {...props} />
                </React.Fragment>
            ))}
        </div>

        <div>
            <NavigationLink
            to={`/${RoutesEnum.Logout}`}
            label={t("navigationItem.logout")}
            icon={<ExitToApp />}
            />
        </div>
    </MenuWrapper>
  )
}

export default NavigationItems
