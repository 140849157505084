import { Auth0Provider } from "@auth0/auth0-react"
import { AUTH0_CONFIG } from "api/config"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { findOrCreateUser } from "store/actions/auth"
import { getPermissions } from "store/actions/permissions"

function Auth0ProviderWithHistory({ children }: any) {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPermissions())
  }, [dispatch])

  const onRedirectCallback = (appState: any) => {
    dispatch(findOrCreateUser())
    history.push(appState.returnTo || window.location.origin)
  }

  return (
    <Auth0Provider
      clientId={AUTH0_CONFIG.client_id}
      domain={AUTH0_CONFIG.domain}
      audience={AUTH0_CONFIG.audience}
      scope={AUTH0_CONFIG.scope}
      redirectUri={AUTH0_CONFIG.redirect_uri}
      useRefreshTokens={AUTH0_CONFIG.useRefreshTokens}
      onRedirectCallback={onRedirectCallback}
      cacheLocation={AUTH0_CONFIG.cacheLocation}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
