import Header from "components/Header"
import Navigation from "components/Navigation"
import Notifications from "components/Notifications"
import React, { ReactNode } from "react"
import { Content, ContentContainer, Main } from "./style"

type Props = {
  children: ReactNode
}

function Layout({ children }: Props) {
  return (
    <Content>
      <Navigation />
      <Main>
        <Header />
        <ContentContainer>
          {children}
        </ContentContainer>
      </Main>
      <Notifications />
    </Content>
  )
}

export default Layout
