import { Auth0ClientOptions } from "@auth0/auth0-spa-js"

function getEnvVariable(name: string) {
  return process.env[name] || ""
}

const AUTH0_DOMAIN = getEnvVariable("REACT_APP_AUTH0_DOMAIN")
const CV_URL = getEnvVariable("REACT_APP_CV_DOMAIN")

export const API_URLS = {
  cv: CV_URL,
  oauth: AUTH0_DOMAIN
}

export const CLIENT_ID: string = getEnvVariable("REACT_APP_CLIENT_ID")
export const BASE_URL: string = getEnvVariable("REACT_APP_BASE_URL")
export const REDIRECT_URI: string = getEnvVariable("REACT_APP_REDIRECT_URI")
export const USER_URL = getEnvVariable("REACT_APP_USERS_DOMAIN")

export const AUTH0_CONFIG: Auth0ClientOptions = {
  audience: "pau-profile",
  cacheLocation: "localstorage",
  client_id: CLIENT_ID,
  domain: AUTH0_DOMAIN,
  redirect_uri: REDIRECT_URI,
  scope: "openid profile email",
  useRefreshTokens: true
}
