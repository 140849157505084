import * as api from "api/version"
import { all, call, put, select, take, takeLatest } from "redux-saga/effects"
import * as cvActions from "../actions/cv"
import * as notificationActions from "../actions/notifications"
import * as tabActions from "../actions/tabs"
import * as actions from "../actions/version"
import * as CVSelectors from "../selectors/cv"

export function* postVersionFlow(
  action: actions.PostVersionAction
): IterableIterator<any> {
  try {
    const { data } = action.payload
    const userId = yield select(CVSelectors.getCvId)
    const response = yield call(api.postVersion, data, userId)
    const version = response && response.data
    yield put(cvActions.getCVById(userId!))
    yield all([take(cvActions.getCVByIdSuccess)])
    yield put(tabActions.changeTab(version.versionId))
    yield put(actions.postVersionSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "postVersionFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.postVersionFail(e))
  }
}

export function* deleteVersionFlow(
  action: actions.DeleteVersionAction
): IterableIterator<any> {
  try {
    const { id } = action.payload
    const userId = yield select(CVSelectors.getCvId)
    yield call(api.deleteVersion, userId, id)
    yield put(cvActions.getCVById(userId!))
    yield all([take(cvActions.getCVByIdSuccess)])
    const newVersions = yield select(CVSelectors.getVersions)
    yield put(tabActions.changeTab(newVersions[0].versionId))
    yield put(actions.deleteVersionSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "deleteVersionFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.deleteVersionfail(e))
  }
}

export function* patchVersionFlow(
  action: actions.PatchVersionAction
): IterableIterator<any> {
  try {
    const version = action.payload.data
    const versionId = version.versionId
    delete version.versionId
    const userId = yield select(CVSelectors.getCvId)
    yield call(api.patchVersion, version, versionId!, userId)
    yield put(cvActions.getCVById(userId!))
    yield put(actions.patchVersionSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "patchVersionFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.patchVersionFail(e))
  }
}

export function* patchVersionSkillFlow(
  action: actions.PatchVersionSkillAction
): IterableIterator<any> {
  try {
    const skill = action.payload.data
    const { versionId, skillId } = skill
    delete skill.versionId
    delete skill.skillId
    const userId = yield select(CVSelectors.getCvId)
    yield call(api.patchVersionSkill, skill, versionId!, userId, skillId)
    yield put(cvActions.getCVById(userId!))
    yield put(actions.patchVersionSkillSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "patchVersionSkillFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.patchVersionSkillFail(e))
  }
}

export function* patchVersionProjectFlow(
  action: actions.PatchVersionProjectAction
): IterableIterator<any> {
  try {
    const project = action.payload.data
    const { versionId, projectId } = project
    delete project.versionId
    delete project.projectId
    const userId = yield select(CVSelectors.getCvId)
    yield call(api.patchVersionProject, project, versionId!, userId, projectId)
    yield put(cvActions.getCVById(userId!))
    yield put(actions.patchVersionProjectSuccess())
  } catch (e) {
    yield put(
      notificationActions.show({
        id: "patchVersionProjectFail",
        message: "error",
        variant: "error"
      })
    )
    yield put(actions.patchVersionProjectFail(e))
  }
}

export default function* versionSaga(): IterableIterator<any> {
  yield takeLatest(actions.Types.POST, postVersionFlow)
  yield takeLatest(actions.Types.DELETE, deleteVersionFlow)
  yield takeLatest(actions.Types.PATCH, patchVersionFlow)
  yield takeLatest(actions.Types.PATCH_VERSION_SKILL, patchVersionSkillFlow)
  yield takeLatest(actions.Types.PATCH_VERSION_PROJECT, patchVersionProjectFlow)
}
