import State from "../state"

export const getPermissions = (state: State): string[] => state.permissions.data

export const hasPermissions = (
  state: State,
  permissionsArray: string[]
): boolean => {
  return !permissionsArray.length ||
    permissionsArray.some(permission => state.permissions.data.includes(permission))
}
