import styled from "@emotion/styled"

export const Content = styled.div`
  min-width: 45rem;
  padding: 2rem;
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
