import React, { memo } from "react"
import { FormattedMessage } from "react-intl"

type Props = {
  label: string | ""
  values?: {}
}

export type TranslationProps = {
  label: string | ""
  values?: {}
}

function Translation({ label, values }: Props) {
  return label ? <FormattedMessage id={label} values={values} /> : null
}

export default memo(Translation)
