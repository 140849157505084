import { InvitationActionTypes, SendInvitationFailureAction, SendInvitationRequestAction, SendInvitationSuccessAction } from "store/actions"

export interface InvitationState {
  isSending: boolean
  error?: string
}

const initialState: InvitationState = {
  isSending: false
}

type InvitationActions = SendInvitationRequestAction | SendInvitationSuccessAction | SendInvitationFailureAction

const invitationReducer = (state = initialState, action: InvitationActions): InvitationState => {
  switch (action.type) {
    case InvitationActionTypes.SEND_INVITATION_REQUEST:
      return { ...state, isSending: true }
    case InvitationActionTypes.SEND_INVITATION_SUCCESS:
      return { ...state, isSending: false, error: undefined }
    case InvitationActionTypes.SEND_INVITATION_FAILURE:
      return { ...state, isSending: false, error: action.payload.error }
    default:
      return state
  }
}

export default invitationReducer
