import styled from "@emotion/styled"
import { empleoTheme } from "config/theme"

export const Content = styled.div`
  display: flex;
`

export const ContentContainer = styled.div`
  padding: 2rem;
  width: 100%;
  background-color: ${empleoTheme.colors.white};
`

export const Main = styled.div`
  width: 100%;
`
