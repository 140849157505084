import { useIntl } from "react-intl"

export const useTrans = () => {
  const intl = useIntl()

  const t = (transId: string, params: {} = {}) => {
    return intl.formatMessage({ id: transId }, params)
  }

  return { t }
}
