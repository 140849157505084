import styled from "@emotion/styled"

type DimensionProps = {
  height: string
  width: string
}

export const LogoWrapper = styled.div<DimensionProps>`
  height: ${props => props.height};
  width: ${props => props.width};
`
