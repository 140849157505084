import { deleteCvById, getCvById, patchCv, postCv } from "api"
import { getUser } from "api/oauth"
import { push } from "connected-react-router"
import { call, put, select, takeLatest } from "redux-saga/effects"
import {
  CVActionTypes,
  DeleteCVByIdAction,
  deleteCVByIdFail,
  deleteCVByIdSuccess,
  getCVById,
  GetCVByIdAction,
  getCVByIdFail,
  getCVByIdSuccess,
  PatchCVAction,
  PostCVAction,
  postCVFail,
  postCVSuccess,
  putCVFail,
  putCVSuccess,
  show as showNotification
 } from "store/actions"

export function* deleteCvByIdFlow(action: DeleteCVByIdAction): IterableIterator<any> {
  try {
    const { id } = action.payload
    yield call(deleteCvById, id)
    yield put(deleteCVByIdSuccess())
    yield put(push("/"))
  } catch (error) {
    if (error instanceof Error) {
      yield put(showNotification({ id: "deleteCvFail", message: "error", variant: "error" }))
      yield put(deleteCVByIdFail(error))
    }
  }
}

export function* postCvFlow(action: PostCVAction): IterableIterator<any> {
  try {
    const { data } = action.payload
    const user = yield call(getUser)
    if (!user) throw Error("No user found")

    const { sub } = user

    yield call(postCv, data, sub)
    yield put(getCVById(sub!))
    yield put(postCVSuccess())
  } catch (error) {
    if (error instanceof Error) {
      yield put(showNotification({ id: "postCvFail", message: "error", variant: "error" }))
      yield put(postCVFail(error))
    }
  }
}

export function* getCvByIdFlow(action: GetCVByIdAction): IterableIterator<any> {
  try {
    const response = yield call(getCvById, action.payload.id)
    if (!response) throw Error("Response failed")

    const { data } = response
    if (!response) throw Error("Couldn't retrieve data")

    yield put(getCVByIdSuccess(data))
  } catch (error) {
    if (error instanceof Error) {
      yield put(getCVByIdFail(error))
    }
  }
}

export function* patchCvFlow(action: PatchCVAction): IterableIterator<any> {
  try {
    const user = yield select(getUser)
    if (!user) return

    const { sub } = user
    const { userId: idToUpdate, ...clonedCv } = action.payload.data

    yield call(patchCv, clonedCv, idToUpdate ? idToUpdate : sub)
    yield put(getCVById(idToUpdate ? idToUpdate : sub))
    yield put(putCVSuccess())
  } catch (error) {
    if (error instanceof Error) {
      yield put(showNotification({ id: "patchCvFail", message: error.message, variant: "error" }))
      yield put(putCVFail(error))
    }
  }
}

export default function* cvSaga(): IterableIterator<any> {
  yield takeLatest(CVActionTypes.DELETE, deleteCvByIdFlow)
  yield takeLatest(CVActionTypes.POST, postCvFlow)
  yield takeLatest(CVActionTypes.GET_BY_ID, getCvByIdFlow)
  yield takeLatest(CVActionTypes.PATCH, patchCvFlow)
}
