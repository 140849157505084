import { Action, Types } from "../actions/onboarding"

export type OnboardingState = {
  placement: number
}

const initialState: OnboardingState = {
  placement: 0
}

export default (state = initialState, action: Action): OnboardingState => {
  switch (action.type) {
    case Types.INDICATOR_CHANGE:
      const { placement } = action.payload
      return {
        ...state,
        placement
      }

    default:
      return state
  }
}
