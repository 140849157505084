import styled from "@emotion/styled"
import { empleoTheme } from "config/theme"
import { NavLink } from "react-router-dom"

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
`

export const LogoHeader = styled.div`
  margin: 2rem 0 2rem 2.9rem;
  height: 3rem;
`

export const styles = (theme: any) => ({
  drawerClose: {
    backgroundColor: empleoTheme.colors.primary.normal,
    overflow: "inherit",
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp
    }),
    width: "8rem"
  },
  drawerOpen: {
    backgroundColor: empleoTheme.colors.primary.normal,
    overflow: "inherit",
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp
    }),
    width: "29rem"
  }
})

export const ClickArrow = styled.div<any>`
  position: absolute;
  cursor: pointer;
  right: -1.2rem;
  background: ${empleoTheme.colors.primary.normal};
  border: 0.1rem solid white;
  color: white;
  border-radius: 50%;
  height: 2.4rem;
  width: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 2.5rem;
  transform: ${props => props.mirror && "scale(-1, 1)"};
`

export const LogoWrap = styled.div`
  cursor: pointer;
  max-width: 14rem;
`

export const UserImage = styled.img`
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
`

export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 4.6rem;
  color: ${empleoTheme.colors.white};
  text-decoration: none;
  overflow: hidden;

  &.active {
    background-color: ${empleoTheme.colors.primary.dark};
  }
`

export const IconContainer = styled.section`
  min-width: 8rem;
  min-height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
