import { CVActions, CVActionTypes } from "store/actions"

export type ExperienceState = {
  errors: object
  loading: boolean
}

const initialState: ExperienceState = {
  errors: {},
  loading: false
}

export default (state = initialState, action: CVActions): ExperienceState => {
  switch (action.type) {
    case CVActionTypes.POST:
      return {
        ...state,
        loading: true
      }

    case CVActionTypes.POST_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case CVActionTypes.POST_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    default:
      return state
  }
}
