import { ProjectInVersion, SkillInVersion, Version } from "models/cv"

export enum Types {
  POST = "VERSION_POST",
  POST_SUCCESS = "VERSION_POST_SUCCESS",
  POST_FAIL = "VERSION_POST_FAIL",
  PATCH = "VERSION_PATCH",
  PATCH_SUCCESS = "VERSION_PATCH_SUCCESS",
  PATCH_FAIL = "VERSION_PATCH_FAIL",
  PATCH_VERSION_SKILL = "VERSION_SKILL_PATCH",
  PATCH_VERSION_SKILL_SUCCESS = "VERSION_SKILL_PATCH_SUCCESS",
  PATCH_VERSION_SKILL_FAIL = "VERSION_SKILL_PATCH_FAIL",
  PATCH_VERSION_PROJECT = "VERSION_PROJECT_PATCH",
  PATCH_VERSION_PROJECT_SUCCESS = "VERSION_PROJECT_PATCH_SUCCESS",
  PATCH_VERSION_PROJECT_FAIL = "VERSION_PROJECT_PATCH_FAIL",
  DELETE = "VERSION_DELETE",
  DELETE_SUCCESS = "VERSION_DELETE_SUCCESS",
  DELETE_FAIL = "VERSION_DELETE_FAIL"
}

export type Action =
  | PostVersionAction
  | PostVersionSuccessAction
  | PostVersionFailAction
  | PatchVersionAction
  | PatchVersionSuccessAction
  | PatchVersionFailAction
  | PatchVersionSkillAction
  | PatchVersionSkillSuccessAction
  | PatchVersionSkillFailAction
  | PatchVersionProjectAction
  | PatchVersionProjectSuccessAction
  | PatchVersionProjectFailAction
  | DeleteVersionAction
  | DeleteVersionSuccessAction
  | DeleteVersionFailAction

export type PostVersionAction = {
  payload: { data: Version }
  type: Types.POST
}

export type PostVersionSuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostVersionFailAction = {
  payload: { errors: object }
  type: Types.POST_FAIL
}

export type PatchVersionAction = {
  type: Types.PATCH
  payload: { data: Version }
}

export type PatchVersionSuccessAction = {
  type: Types.PATCH_SUCCESS
}

export type PatchVersionFailAction = {
  type: Types.PATCH_FAIL
  payload: { errors: object }
}

export type PatchVersionSkillAction = {
  type: Types.PATCH_VERSION_SKILL
  payload: { data: SkillInVersion }
}

export type PatchVersionSkillSuccessAction = {
  type: Types.PATCH_VERSION_SKILL_SUCCESS
}

export type PatchVersionSkillFailAction = {
  type: Types.PATCH_VERSION_SKILL_FAIL
  payload: { errors: object }
}

export type PatchVersionProjectAction = {
  type: Types.PATCH_VERSION_PROJECT
  payload: { data: ProjectInVersion }
}

export type PatchVersionProjectSuccessAction = {
  type: Types.PATCH_VERSION_PROJECT_SUCCESS
}

export type PatchVersionProjectFailAction = {
  type: Types.PATCH_VERSION_PROJECT_FAIL
  payload: { errors: object }
}

export type DeleteVersionAction = {
  payload: { id: string | number }
  type: Types.DELETE
}

export type DeleteVersionSuccessAction = {
  type: Types.DELETE_SUCCESS
}

export type DeleteVersionFailAction = {
  payload: { errors: object }
  type: Types.DELETE_FAIL
}

export function deleteVersion(id: string | number): DeleteVersionAction {
  return {
    payload: { id },
    type: Types.DELETE
  }
}

export function deleteVersionSuccess(): DeleteVersionSuccessAction {
  return {
    type: Types.DELETE_SUCCESS
  }
}

export function deleteVersionfail(errors: object): DeleteVersionFailAction {
  return {
    payload: { errors },
    type: Types.DELETE_FAIL
  }
}

export function postVersion(data: Version): PostVersionAction {
  return {
    payload: { data },
    type: Types.POST
  }
}

export function postVersionSuccess(): PostVersionSuccessAction {
  return {
    type: Types.POST_SUCCESS
  }
}

export function postVersionFail(errors: object): PostVersionFailAction {
  return {
    payload: { errors },
    type: Types.POST_FAIL
  }
}

export function patchVersion(data: Version): PatchVersionAction {
  return {
    payload: { data },
    type: Types.PATCH
  }
}
export function patchVersionSuccess(): PatchVersionSuccessAction {
  return {
    type: Types.PATCH_SUCCESS
  }
}
export function patchVersionFail(errors: object): PatchVersionFailAction {
  return {
    payload: { errors },
    type: Types.PATCH_FAIL
  }
}

export function patchVersionSkill(
  data: SkillInVersion
): PatchVersionSkillAction {
  return {
    payload: { data },
    type: Types.PATCH_VERSION_SKILL
  }
}
export function patchVersionSkillSuccess(): PatchVersionSkillSuccessAction {
  return {
    type: Types.PATCH_VERSION_SKILL_SUCCESS
  }
}

export function patchVersionSkillFail(
  errors: object
): PatchVersionSkillFailAction {
  return {
    payload: { errors },
    type: Types.PATCH_VERSION_SKILL_FAIL
  }
}

export function patchVersionProject(
  data: ProjectInVersion
): PatchVersionProjectAction {
  return {
    payload: { data },
    type: Types.PATCH_VERSION_PROJECT
  }
}
export function patchVersionProjectSuccess(): PatchVersionProjectSuccessAction {
  return {
    type: Types.PATCH_VERSION_PROJECT_SUCCESS
  }
}

export function patchVersionProjectFail(
  errors: object
): PatchVersionProjectFailAction {
  return {
    payload: { errors },
    type: Types.PATCH_VERSION_PROJECT_FAIL
  }
}
