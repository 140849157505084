import Cv from "models/cv"

export enum CVActionTypes {
  CLEAR = "CV_CLEAR",
  DELETE = "CV_DELETE",
  DELETE_SUCCESS = "CV_DELETE_SUCCESS",
  DELETE_FAIL = "CV_DELETE_FAIL",
  POST = "CV_POST",
  POST_SUCCESS = "CV_POST_SUCCESS",
  POST_FAIL = "CV_POST_FAIL",
  GET_BY_ID = "CV_GET_BY_ID",
  GET_BY_ID_SUCCESS = "CV_GET_BY_ID_SUCCESS",
  GET_BY_ID_FAIL = "CV_GET_BY_ID_FAIL",
  PATCH = "CV_PATCH",
  PATCH_SUCCESS = "CV_PATCH_SUCCESS",
  PATCH_FAIL = "CV_PATCH_FAIL"
}

export type CVActions =
  | ClearCVAction
  | DeleteCVByIdAction
  | DeleteCVByIdSuccessAction
  | DeleteCVByIdFailAction
  | PostCVAction
  | PostCVSuccessAction
  | PostCVFailAction
  | GetCVByIdAction
  | GetCVByIdSuccessAction
  | GetCVByIdFailAction
  | PatchCVAction
  | PatchCVSuccessAction
  | PatchCVFailAction

export type ClearCVAction = {
  type: CVActionTypes.CLEAR
}

export type PostCVAction = {
  payload: { data: Cv }
  type: CVActionTypes.POST
}

export type PostCVSuccessAction = {
  type: CVActionTypes.POST_SUCCESS
}

export type PostCVFailAction = {
  payload: { errors: object }
  type: CVActionTypes.POST_FAIL
}

export type DeleteCVByIdAction = {
  payload: { id: string }
  type: CVActionTypes.DELETE
}

export type DeleteCVByIdSuccessAction = {
  type: CVActionTypes.DELETE_SUCCESS
}

export type DeleteCVByIdFailAction = {
  payload: { errors: object }
  type: CVActionTypes.DELETE_FAIL
}

export type GetCVByIdAction = {
  type: CVActionTypes.GET_BY_ID
  payload: { id: string }
}

export type GetCVByIdSuccessAction = {
  type: CVActionTypes.GET_BY_ID_SUCCESS
  payload: { data: Cv | undefined }
}

export type GetCVByIdFailAction = {
  type: CVActionTypes.GET_BY_ID_FAIL
  payload: { errors: object }
}

export type PatchCVAction = {
  type: CVActionTypes.PATCH
  payload: { data: Cv }
}

export type PatchCVSuccessAction = {
  type: CVActionTypes.PATCH_SUCCESS
}

export type PatchCVFailAction = {
  type: CVActionTypes.PATCH_FAIL
  payload: { errors: object }
}

export function deleteCVById(id: string): DeleteCVByIdAction {
  return {
    payload: { id },
    type: CVActionTypes.DELETE
  }
}

export function deleteCVByIdSuccess(): DeleteCVByIdSuccessAction {
  return {
    type: CVActionTypes.DELETE_SUCCESS
  }
}

export function deleteCVByIdFail(errors: object): DeleteCVByIdFailAction {
  return {
    payload: { errors },
    type: CVActionTypes.DELETE_FAIL
  }
}

export function postCV(data: Cv): PostCVAction {
  return {
    payload: { data },
    type: CVActionTypes.POST
  }
}

export function postCVSuccess(): PostCVSuccessAction {
  return {
    type: CVActionTypes.POST_SUCCESS
  }
}

export function postCVFail(errors: object): PostCVFailAction {
  return {
    payload: { errors },
    type: CVActionTypes.POST_FAIL
  }
}
export function getCVById(id: string): GetCVByIdAction {
  return {
    payload: { id },
    type: CVActionTypes.GET_BY_ID
  }
}
export function getCVByIdSuccess(data: Cv | undefined): GetCVByIdSuccessAction {
  return {
    payload: { data },
    type: CVActionTypes.GET_BY_ID_SUCCESS
  }
}
export function getCVByIdFail(errors: object): GetCVByIdFailAction {
  return {
    payload: { errors },
    type: CVActionTypes.GET_BY_ID_FAIL
  }
}
export function putCV(data: Cv): PatchCVAction {
  return {
    payload: { data },
    type: CVActionTypes.PATCH
  }
}
export function putCVSuccess(): PatchCVSuccessAction {
  return {
    type: CVActionTypes.PATCH_SUCCESS
  }
}
export function putCVFail(errors: object): PatchCVFailAction {
  return {
    payload: { errors },
    type: CVActionTypes.PATCH_FAIL
  }
}

export function clearCv(): ClearCVAction {
  return {
    type: CVActionTypes.CLEAR
  }
}
