export enum AuthActionTypes {
  FIND_OR_CREATE_USER = "FIND_OR_CREATE_USER"
}

export type AuthActions = FindOrCreateUserAction

export type FindOrCreateUserAction = {
  type: AuthActionTypes.FIND_OR_CREATE_USER
}

export function findOrCreateUser(): FindOrCreateUserAction {
  return {
    type: AuthActionTypes.FIND_OR_CREATE_USER
  }
}
