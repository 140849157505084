import { AxiosPromise } from "axios"
import Cv, {
  Education,
  Experience,
  Language,
  Project,
  Reference,
  Skill
} from "models/cv"
import { cvApi, userApi } from "./api"

export const getCvById = (id: string): AxiosPromise => cvApi(`/cv/${id}`)

export const getUserValidation = (data: Cv): AxiosPromise =>
  userApi("/validate", {
    data,
    method: "POST"
  })

export const getCv = (): AxiosPromise =>
  cvApi("/users", {
    method: "GET"
  })

export const patchCv = (data: Cv, userId: string): AxiosPromise =>
  cvApi(`/cv/${userId}`, {
    data,
    method: "PATCH"
  })

export const deleteCvById = (id: string): AxiosPromise =>
  cvApi(`/cv/${id}`, {
    method: "DELETE"
  })

export const postEducation = (data: Education, userId: string): AxiosPromise =>
  cvApi(`/cv/${userId}/education`, { method: "POST", data })

export const deleteEducationById = (
  id: string,
  educationId: string
): AxiosPromise =>
  cvApi(`/cv/${id}/education/${educationId}`, {
    method: "DELETE"
  })

export const patchEducation = (
  data: Education,
  userId: string,
  educationId: string
): AxiosPromise =>
  cvApi(`/cv/${userId}/education/${educationId}`, {
    data,
    method: "PATCH"
  })

export const postReference = (data: Reference, userId: string): AxiosPromise =>
  cvApi(`/cv/${userId}/reference`, { method: "POST", data })

export const deleteReferenceById = (
  id: string,
  referenceId: string
): AxiosPromise =>
  cvApi(`/cv/${id}/reference/${referenceId}`, {
    method: "DELETE"
  })

export const patchReference = (
  data: Reference,
  userId: string,
  referenceId: string
): AxiosPromise =>
  cvApi(`/cv/${userId}/reference/${referenceId}`, {
    data,
    method: "PATCH"
  })

export const postCv = (data: Cv, userId: string): AxiosPromise =>
  cvApi(`/cv/${userId}`, { method: "POST", data })

export const postExperience = (
  data: Experience,
  userId: string
): AxiosPromise => cvApi(`/cv/${userId}/experience`, { method: "POST", data })

export const deleteExperience = (
  userId: string,
  experienceId: string
): AxiosPromise =>
  cvApi(`/cv/${userId}/experience/${experienceId}`, { method: "DELETE" })

export const patchExperience = (
  data: Experience,
  userId: string,
  experienceId: string
): AxiosPromise =>
  cvApi(`/cv/${userId}/experience/${experienceId}`, {
    data,
    method: "PATCH"
  })

export const postLanguage = (data: Language, userId: string): AxiosPromise =>
  cvApi(`/cv/${userId}/language`, { method: "POST", data })

export const deleteLanguage = (
  userId: string,
  languageId: string
): AxiosPromise =>
  cvApi(`/cv/${userId}/language/${languageId}`, { method: "DELETE" })

export const patchLanguage = (
  data: Language,
  userId: string,
  languageId: string
): AxiosPromise =>
  cvApi(`/cv/${userId}/language/${languageId}`, {
    data,
    method: "PATCH"
  })

export const postProject = (data: Project, userId: string): AxiosPromise =>
  cvApi(`/cv/${userId}/project`, { method: "POST", data })

export const deleteProject = (
  userId: string,
  projectId: string
): AxiosPromise =>
  cvApi(`/cv/${userId}/project/${projectId}`, { method: "DELETE" })

export const patchProject = (
  data: Project,
  userId: string,
  projectId: string
): AxiosPromise =>
  cvApi(`/cv/${userId}/project/${projectId}`, {
    data,
    method: "PATCH"
  })

export const getSkills = (userId: string): AxiosPromise =>
  cvApi(`/cv/${userId}/skill`)

export const patchSkill = (
  data: Skill,
  skillId: string | number,
  userId: string
): AxiosPromise =>
  cvApi(`/cv/${userId}/skill/${skillId}`, {
    data,
    method: "PATCH"
  })

export const deleteSkill = (
  userId: string,
  skillId: string | number
): AxiosPromise =>
  cvApi(`/cv/${userId}/skill/${skillId}`, {
    method: "DELETE"
  })

export const postSkill = (data: Skill, userId: string): AxiosPromise =>
  cvApi(`/cv/${userId}/skill`, { method: "POST", data })
