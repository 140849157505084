import { createTheme } from "@material-ui/core/styles"
import { brighten, opacify } from "utils"

export const empleoTheme = {
  colors: {
    background: "#fff",
    body: "#333333",
    border: "#e6e6e6",
    boxShadow: {
      dark: "rgba(43, 43, 43, 0.5)",
      normal: "rgba(183, 183, 183, 0.5)"
    },
    error: {
      dark: "#ff0000",
      light: "",
      normal: "#ff6b6b"
    },
    grey: {
      normal: "#000000",
      40: "",
      60: "",
      70: "",
      80: "",
      90: "",
      95: ""
    },
    info: "#f2f2f2",
    link: {
      dark: "#1566b3",
      normal: "#1e92ff"
    },
    primary: {
      dark: "",
      editable: "",
      hover: "",
      light: "",
      normal: "#00BDEE"
    },
    secondary: {
      dark: "",
      hover: "",
      light: "",
      normal: "#19847a"
    },
    success: "#008900",
    tertiary: {
      10: "",
      20: "",
      30: ""
    },
    warning: {
      light: "#ffbf00",
      normal: "#fd4376"
    },
    white: "#ffffff"
  },
  typography: {
    fontFamilySansSerif: "'Roboto', sans-serif",
    fontSize: "1.6rem",
    fontSizeHTML: 10,
    fontSize_h1: "2.5rem",
    fontSize_h2: "2.4rem",
    fontSize_h3: "2rem",
    fontSize_h4: "1.5rem",
    fontSize_h5: "1.28rem",
    fontSize_h6: "1.28rem",
    fontSize_input: "1.4rem",
    fontSize_sm: "1.024rem",
    fontWeight: 500,
    lineHeight: 1.5
  }
}

empleoTheme.colors.grey[40] = brighten(empleoTheme.colors.grey.normal, 40) // #666
empleoTheme.colors.grey[60] = brighten(empleoTheme.colors.grey.normal, 60) // #999
empleoTheme.colors.grey[70] = brighten(empleoTheme.colors.grey.normal, 70) // #b3b3b3
empleoTheme.colors.grey[80] = brighten(empleoTheme.colors.grey.normal, 80) // #ccc
empleoTheme.colors.grey[90] = brighten(empleoTheme.colors.grey.normal, 90) // #e6e6e6
empleoTheme.colors.grey[95] = brighten(empleoTheme.colors.grey.normal, 95) // #f2f2f2
empleoTheme.colors.primary.dark = brighten(
  empleoTheme.colors.primary.normal,
  -20
)
empleoTheme.colors.primary.editable = opacify(
  empleoTheme.colors.primary.normal,
  0.1
)
empleoTheme.colors.primary.light = opacify(
  empleoTheme.colors.primary.normal,
  0.2
)
empleoTheme.colors.primary.hover = brighten(
  empleoTheme.colors.primary.normal,
  -10
)
empleoTheme.colors.secondary.dark = brighten(
  empleoTheme.colors.secondary.normal,
  -20
)
empleoTheme.colors.secondary.light = opacify(
  empleoTheme.colors.secondary.normal,
  0.2
)
empleoTheme.colors.secondary.hover = brighten(
  empleoTheme.colors.secondary.normal,
  20
)

empleoTheme.colors.error.light = opacify(empleoTheme.colors.error.normal, 0.5)
empleoTheme.colors.tertiary[10] = brighten(
  empleoTheme.colors.primary.normal,
  10
)
empleoTheme.colors.tertiary[20] = brighten(
  empleoTheme.colors.primary.normal,
  20
)
empleoTheme.colors.tertiary[30] = brighten(
  empleoTheme.colors.primary.normal,
  30
)

const primary = empleoTheme.colors.primary.normal
const white = empleoTheme.colors.white

const theme = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        background: "rgba(0, 0, 0, 0.1)"
      }
    },
    MuiButton: {
      containedPrimary: {
        color: white
      }
    },
    MuiCssBaseline: {
      "@global": {
        "::-webkit-scrollbar": {
          width: "1.7rem"
        },
        "::-webkit-scrollbar-thumb": {
          background: empleoTheme.colors.grey[60],
          borderRadius: "0.6rem"
        },
        ":root": {
          "--primary": primary
        },
        body: {
          fontSize: empleoTheme.typography.fontSize
        },
        html: {
          font: `${empleoTheme.typography.fontWeight} ${
            empleoTheme.typography.fontSizeHTML
          } / ${empleoTheme.typography.lineHeight} ${
            empleoTheme.typography.fontFamilySansSerif
          }`
        }
      }
    },
    MuiPaper: {
      elevation1: {
        WebkitBoxShadow: `0 0.2rem 0.4rem 0 ${
          empleoTheme.colors.boxShadow.dark
        }`,
        boxShadow: `0 0.2rem 0.4rem 0 ${empleoTheme.colors.boxShadow.dark}`
      },
      elevation2: {
        WebkitBoxShadow: `0 0.2rem 0.4rem 0.3rem ${
          empleoTheme.colors.boxShadow.normal
        }`,
        boxShadow: `0 0.2rem 0.4rem 0.3rem ${
          empleoTheme.colors.boxShadow.normal
        }`
      }
    },
    MuiSelect: {
      root: {
        position: "initial"
      },
      select: {
        "&:focus": {
          backgroundColor: "transparent"
        },
        height: "auto",
        paddingLeft: "1rem",
        paddingRight: "5.5rem",
        width: "calc(100% - 6.5rem)"
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: `0.1rem solid ${empleoTheme.colors.grey[90]}`
      }
    },
    MuiTableRow: {
      root: {
        "&$hover:hover": {
          backgroundColor: opacify(primary, 0.1)
        },
        "&$selected": {
          "&:nth-of-type(even)": {
            backgroundColor: empleoTheme.colors.primary.light,
            borderColor: primary
          },
          backgroundColor: empleoTheme.colors.primary.light,
          borderColor: primary
        },
        "&:nth-of-type(even)": {
          "& .MuiButtonBase-RowOptions": {
            color: empleoTheme.colors.body
          },
          backgroundColor: empleoTheme.colors.grey[95]
        },
        cursor: "pointer",
        height: "5.5rem"
      }
    }
  },
  palette: {
    background: {
      default: empleoTheme.colors.background
    },
    common: {
      black: empleoTheme.colors.body
    },
    error: {
      main: empleoTheme.colors.error.normal
    },
    primary: {
      main: primary
    },
    text: {
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      primary: empleoTheme.colors.body,
      secondary: empleoTheme.colors.grey[40]
    }
  },
  typography: {
    body1: {
      fontSize: empleoTheme.typography.fontSize
    },
    body2: {
      fontSize: empleoTheme.typography.fontSize
    },
    button: {
      fontSize: empleoTheme.typography.fontSize
    },
    h1: {
      color: empleoTheme.colors.body,
      fontSize: empleoTheme.typography.fontSize_h1,
      fontWeight: empleoTheme.typography.fontWeight
    },
    h2: {
      color: empleoTheme.colors.body,
      fontSize: empleoTheme.typography.fontSize_h2,
      fontWeight: empleoTheme.typography.fontWeight
    },
    h3: {
      color: empleoTheme.colors.body,
      fontSize: empleoTheme.typography.fontSize_h3,
      fontWeight: empleoTheme.typography.fontWeight
    },
    h4: {
      color: empleoTheme.colors.body,
      fontSize: empleoTheme.typography.fontSize_h4,
      fontWeight: empleoTheme.typography.fontWeight
    },
    h5: {
      color: empleoTheme.colors.body,
      fontSize: empleoTheme.typography.fontSize_h5,
      fontWeight: empleoTheme.typography.fontWeight
    },
    h6: {
      color: empleoTheme.colors.body,
      fontSize: empleoTheme.typography.fontSize_h6,
      fontWeight: empleoTheme.typography.fontWeight
    },
    htmlFontSize: 10
  }
})

export default theme
